import { SplitsQuery, SplitsQueryVariables } from './API/types';
import { generateClient } from 'aws-amplify/api';
import { GeneratedQuery, ParsedResponse } from './util';

const listSplitsString =
  `query listSplits($query: SqlQuery, $offset_id: String, $offset: String, $limit: Int, $direction: MoveDirection) {
  splits(query: $query, offset_id: $offset_id, offset: $offset, limit: $limit, direction: $direction) {
                    items {
                      __typename
                      account_code
                      amount
                      currency
                      created_at
                      id
                      merchant {
                        ach_active
                        card_active
                        cash_active
                        country_code
                        is_system
                        merchant_name
                        merchant_uid
                        parent_merchant_uid
                        submitted_onboarding
                      }
                      metadata
                      payment_method {
                        address_line1
                        address_line2
                        bank_account_type
                        bank_code
                        barcode_id
                        card_brand
                        card_type
                        city
                        country
                        exp_date
                        full_name
                        is_active
                        issuing_country_code
                        last_four
                        merchant_uid
                        metadata
                        payment_method_id
                        payment_type
                        payor {
                          address_line1
                          address_line2
                          city
                          country
                          email
                          full_name
                          merchant_uid
                          metadata
                          payor_id
                          phone
                          postal_code
                          region
                        }
                        postal_code
                        region
                        wallet_type
                      }
                      reference
                      settlement_batch
                      transaction_id
                      updated_row_at
                    }
                      total_row_count
                    }
}` as GeneratedQuery<SplitsQueryVariables, SplitsQuery>;

export const list = async (
  variables: SplitsQueryVariables,
): Promise<ParsedResponse<SplitsQuery>> => {
  try {
    const client = generateClient();
    const response = await client.graphql({
      query: listSplitsString,
      variables,
    });
    if (response.errors) {
      return {
        data: null,
        errors: response.errors.map((e: any) => e.message),
      };
    }
    return {
      data: response.data,
      errors: null,
    };
  } catch (error) {
    console.error('Error fetching splits:', error);
    return {
      data: null,
      errors:
        error instanceof Error ? [error.message] : ['Unknown error occurred'],
    };
  }
};
