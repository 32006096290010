/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type PaymentMethodInput = {
  ach?: AchInput | null;
  canadian_eft?: CanadianEftInput | null;
  card?: CardInput | null;
  metadata?: string | null;
  payor?: PayorInput | null;
  payor_id?: string | null;
};

export type AchInput = {
  account_number: string;
  account_type: BankAccountType;
  address_line1?: string | null;
  address_line2?: string | null;
  city?: string | null;
  country?: string | null;
  name_on_account: string;
  postal_code?: string | null;
  region?: string | null;
  routing_number: string;
};

export enum BankAccountType {
  BUSINESS_CHECKING = 'BUSINESS_CHECKING',
  BUSINESS_SAVINGS = 'BUSINESS_SAVINGS',
  PERSONAL_CHECKING = 'PERSONAL_CHECKING',
  PERSONAL_SAVINGS = 'PERSONAL_SAVINGS',
}

export type CanadianEftInput = {
  account_number: string;
  account_type: BankAccountType;
  institution_number: string;
  name_on_account: string;
  transit_number: string;
};

export type CardInput = {
  address_line1?: string | null;
  address_line2?: string | null;
  card_number: string;
  city?: string | null;
  country?: string | null;
  exp_date: CardExpirationInput;
  full_name?: string | null;
  postal_code: string;
  region?: string | null;
  security_code: string;
};

export type CardExpirationInput = {
  month: string;
  year: string;
};

export type PayorInput = {
  address_line1?: string | null;
  address_line2?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  full_name?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  phone?: string | null;
  postal_code?: string | null;
  region?: string | null;
};

export enum FeeMode {
  INTERCHANGE = 'INTERCHANGE',
  MERCHANT_FEE = 'MERCHANT_FEE',
  SERVICE_FEE = 'SERVICE_FEE',
}

export enum HealthExpenseType {
  CLINICAL = 'CLINICAL',
  COPAY = 'COPAY',
  DENTAL = 'DENTAL',
  HEALTHCARE = 'HEALTHCARE',
  RX = 'RX',
  TRANSIT = 'TRANSIT',
  VISION = 'VISION',
}

export type AdditionalPurchaseDataInput = {
  // Max 99 line items
  level3_data_line_item?: Array<Level3DataLineItemInput | null> | null;
  level3_data_summary?: Level3DataSummaryInput | null;
};

export type Level3DataLineItemInput = {
  item_code?: string | null;
  item_description?: string | null;
  item_qty_exp?: number | null;
  prod_code?: string | null;
  qty?: number | null;
  tax_amount?: number | null;
  tax_ind?: TaxIndicatorType | null;
  tax_rate?: number | null;
  tax_rt_exp?: number | null;
  tax_type_id?: TaxType | null;
  unit_cost?: number | null;
  unit_of_msure?: string | null;
};

export enum TaxIndicatorType {
  NO_TAX_INFO_PROVIDED = 'NO_TAX_INFO_PROVIDED',
  NOT_TAXABLE = 'NOT_TAXABLE',
  TAX_AMOUNT_PROVIDED = 'TAX_AMOUNT_PROVIDED',
}

export enum TaxType {
  CITY_SALES = 'CITY_SALES',
  ENERGY = 'ENERGY',
  GST = 'GST',
  LOCAL_SALES = 'LOCAL_SALES',
  MUNICIPAL_SALES = 'MUNICIPAL_SALES',
  NATIONAL_SALES = 'NATIONAL_SALES',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
  OTHER = 'OTHER',
  PST = 'PST',
  ROOM = 'ROOM',
  OCCUPANCY = 'OCCUPANCY',
  STATE_SALES = 'STATE_SALES',
  UNKNOWN = 'UNKNOWN',
  VAT = 'VAT',
}

export type Level3DataSummaryInput = {
  dest_postal_code?: string | null;
  discnt_amt?: number | null;
  duty_amt?: number | null;
  frght_amt?: number | null;
  order_num?: string | null;
  prod_desc?: Array<string | null> | null;
  purch_idfr?: string | null;
  tax_amt?: number | null;
  tax_ind?: TaxIndicatorType | null;
};

export type Authorization = {
  __typename: 'Authorization';
  account_code?: string | null;
  additional_purchase_data?: AdditionalPurchaseData | null;
  amount: number;
  authorization_date: string;
  authorization_id: string;
  avs_status?: string | null;
  captured_amount?: number | null;
  currency: string;
  device_id?: string | null;
  expiration_date?: string | null;
  failure_reasons?: Array<string | null> | null;
  fee_mode: FeeMode;
  fees: number;
  invoice?: Invoice | null;
  merchant_uid: string;
  metadata?: string | null;
  payment_method: PaymentMethodToken;
  processor?: string | null;
  reference?: string | null;
  sale_id?: string | null;
  status: AuthorizationStatus;
  timezone?: string | null;
  transaction_id?: string | null;
  updated_row_at?: string | null;
};

export type AdditionalPurchaseData = {
  __typename: 'AdditionalPurchaseData';
  level3_data_line_item?: Array<Level3DataLineItem | null> | null;
  level3_data_summary?: Level3DataSummary | null;
};

export type Level3DataLineItem = {
  __typename: 'Level3DataLineItem';
  item_code?: string | null;
  item_description?: string | null;
  item_qty_exp?: number | null;
  prod_code?: string | null;
  qty?: number | null;
  tax_amount?: number | null;
  tax_ind?: TaxIndicatorType | null;
  tax_rate?: number | null;
  tax_rt_exp?: number | null;
  tax_type_id?: TaxType | null;
  unit_cost?: number | null;
  unit_of_msure?: string | null;
};

export type Level3DataSummary = {
  __typename: 'Level3DataSummary';
  dest_postal_code?: string | null;
  discnt_amt?: number | null;
  duty_amt?: number | null;
  frght_amt?: number | null;
  order_num?: string | null;
  prod_desc?: Array<string | null> | null;
  purch_idfr?: string | null;
  tax_amt?: number | null;
  tax_ind?: TaxIndicatorType | null;
};

export type Invoice = {
  __typename: 'Invoice';
  account_code?: string | null;
  created_date?: string | null;
  currency?: string | null;
  due_by?: string | null;
  fee_mode?: FeeMode | null;
  invoice_amount?: number | null;
  invoice_date?: string | null;
  invoice_description?: string | null;
  invoice_id?: string | null;
  invoice_name?: string | null;
  merchant_invoice_number?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  offline_transactions?: Array<OfflineTransaction | null> | null;
  payor?: Payor | null;
  reference?: string | null;
  settings?: InvoiceSettings | null;
  status?: InvoiceStatus | null;
  total_paid_amount?: number | null;
};

export type OfflineTransaction = {
  __typename: 'OfflineTransaction';
  amount?: number | null;
  instance_id?: string | null;
  invoice_id?: string | null;
  note?: string | null;
  transaction_date?: string | null;
  type?: OfflineTransactionType | null;
};

export enum OfflineTransactionType {
  ACH = 'ACH',
  CARD = 'CARD',
  CASH = 'CASH',
  OTHER = 'OTHER',
}

export type Payor = {
  __typename: 'Payor';
  address_line1?: string | null;
  address_line2?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  full_name?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  payor_id?: string | null;
  phone?: string | null;
  postal_code?: string | null;
  region?: string | null;
};

export type InvoiceSettings = {
  __typename: 'InvoiceSettings';
  accepted_payment_methods?: AcceptedPaymentMethods | null;
  is_secure?: boolean | null;
  require_payor_address?: boolean | null;
  security_pin?: string | null;
};

export type AcceptedPaymentMethods = {
  __typename: 'AcceptedPaymentMethods';
  ach?: boolean | null;
  card?: boolean | null;
  cash?: boolean | null;
};

export enum InvoiceStatus {
  NOT_PAID = 'NOT_PAID',
  PARTIALLY_PAID = 'PARTIALLY_PAID',
  PAID = 'PAID',
}

export type PaymentMethodToken = {
  __typename: 'PaymentMethodToken';
  address_line1?: string | null;
  address_line2?: string | null;
  bank_account_type?: BankAccountType | null;
  bank_code?: string | null;
  barcode_id?: string | null;
  card_brand?: string | null;
  card_type?: CardType | null;
  city?: string | null;
  country?: string | null;
  exp_date?: string | null;
  full_name?: string | null;
  is_active?: boolean | null;
  issuing_country_code?: string | null;
  last_four?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  payment_method_id?: string | null;
  payment_type?: PaymentType | null;
  payor?: Payor | null;
  postal_code?: string | null;
  region?: string | null;
  wallet_type?: WalletType | null;
};

export enum CardType {
  BUSINESS_CREDIT = 'BUSINESS_CREDIT',
  BUSINESS_DEBIT = 'BUSINESS_DEBIT',
  CREDIT_CARD = 'CREDIT_CARD',
  DEBIT_CARD = 'DEBIT_CARD',
  PREPAID_CARD = 'PREPAID_CARD',
}

export enum PaymentType {
  ACH = 'ACH',
  CARD = 'CARD',
  CASH = 'CASH',
}

export enum WalletType {
  APPLE_PAY = 'APPLE_PAY',
  CLICK_TO_PAY = 'CLICK_TO_PAY',
  GOOGLE_PAY = 'GOOGLE_PAY',
  SAMSUNG_PAY = 'SAMSUNG_PAY',
  VISA_STAGED = 'VISA_STAGED',
}

export enum AuthorizationStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED',
}

export enum BackofficeKeyEntityLevel {
  MERCHANT = 'MERCHANT',
  PARTNER = 'PARTNER',
  SYSTEM = 'SYSTEM',
}

export type BackofficeKeyDataWithKey = {
  __typename: 'BackofficeKeyDataWithKey';
  api_key?: string | null;
  created_date?: string | null;
  enabled?: boolean | null;
  entity_level?: BackofficeKeyEntityLevel | null;
  key_name?: string | null;
  last_accessed_date?: string | null;
  merchant_uid?: string | null;
};

export type BarcodeInput = {
  account_code?: string | null;
  amount: number;
  days_until_expiration?: number | null;
  email_or_phone: string;
  expiration_date?: string | null;
  full_name: string;
  invoice_id?: string | null;
  merchant_uid: string;
  metadata?: string | null;
  payor?: PayorInput | null;
  payor_id?: string | null;
  reference?: string | null;
  sale_id?: string | null;
};

export type Barcode = {
  __typename: 'Barcode';
  account_code?: string | null;
  amount: number;
  barcode_id: string;
  barcode_url?: string | null;
  email_or_phone: string;
  expiration_date?: string | null;
  fees?: number | null;
  full_name: string;
  invoice_id?: string | null;
  merchant_uid: string;
  metadata?: string | null;
  payor?: Payor | null;
  payor_id?: string | null;
  reference?: string | null;
  sale_id?: string | null;
};

export type BarcodePaymentResponse = {
  __typename: 'BarcodePaymentResponse';
  confirmation_code: string;
  status: BarcodePaymentStatus;
  status_message: string;
};

export enum BarcodePaymentStatus {
  PAYMENT_ACCEPTED = 'PAYMENT_ACCEPTED',
  PAYMENT_DECLINED = 'PAYMENT_DECLINED',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
}

export enum TransactionStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  RETURNED = 'RETURNED',
  SETTLED = 'SETTLED',
  SUCCEEDED = 'SUCCEEDED',
  VOIDED = 'VOIDED',
}

export type CardPresentPayment = {
  __typename: 'CardPresentPayment';
  amount?: number | null;
  card_brand?: string | null;
  created_at?: string | null;
  currency?: string | null;
  failure_reason?: Array<string | null> | null;
  last_four?: string | null;
  service_fee?: number | null;
  status?: TransactionStatus | null;
  transaction_id?: string | null;
};

export type SplitInput = {
  account_code?: string | null;
  amount: number;
  metadata?: string | null;
  merchant_uid: string;
  reference?: string | null;
};

export type Transaction = {
  __typename: 'Transaction';
  account_code?: string | null;
  ach_return_details?: AchReturnDetails | null;
  additional_purchase_data?: AdditionalPurchaseData | null;
  authorization_id?: string | null;
  avs_status?: string | null;
  currency?: string | null;
  device_id?: string | null;
  dispute_status?: DisputeStatus | null;
  failure_reasons?: Array<string | null> | null;
  fee_mode?: FeeMode | null;
  fees?: number | null;
  flag_for_review?: TransactionReviewStatus | null;
  gross_amount?: number | null;
  invoice?: Invoice | null;
  is_settled?: boolean | null;
  merchant?: ListMerchant | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  net_amount?: number | null;
  parent_id?: string | null;
  payment_method?: PaymentMethodToken | null;
  processor?: string | null;
  recurring?: RecurringPayment | null;
  reference?: string | null;
  refund_reason?: RefundReason | null;
  refund_voidable?: boolean | null;
  refunded_amount?: number | null;
  sale_id?: string | null;
  settlement_batch?: number | null;
  splits?: Array<Split | null> | null;
  status?: TransactionStatus | null;
  timezone?: string | null;
  transaction_date?: string | null;
  transaction_id?: string | null;
  transaction_type?: TransactionType | null;
  updated_row_at?: string | null;
};

export type AchReturnDetails = {
  __typename: 'AchReturnDetails';
  return_code?: string | null;
  return_details?: string | null;
  transfer_type?: AchReturnTransferType | null;
};

export enum AchReturnTransferType {
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export enum DisputeStatus {
  INQUIRY = 'INQUIRY',
  LOST = 'LOST',
  PENDING = 'PENDING',
  WON = 'WON',
}

export enum TransactionReviewStatus {
  EXCEEDS_AUTH = 'EXCEEDS_AUTH',
  EXCEEDS_THRESHOLD = 'EXCEEDS_THRESHOLD',
  POTENTIAL_DUPLICATE = 'POTENTIAL_DUPLICATE',
}

export type ListMerchant = {
  __typename: 'ListMerchant';
  ach_active?: boolean | null;
  card_active?: boolean | null;
  cash_active?: boolean | null;
  country_code?: string | null;
  is_system?: boolean | null;
  merchant_name?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  parent_merchant_uid?: string | null;
  submitted_onboarding?: boolean | null;
  updated_row_at?: string | null;
};

export type RecurringPayment = {
  __typename: 'RecurringPayment';
  account_code?: string | null;
  amount_per_payment?: number | null;
  created_date?: string | null;
  currency?: string | null;
  fee_mode?: FeeMode | null;
  fee_per_payment?: number | null;
  is_active?: boolean | null;
  is_processing?: boolean | null;
  recurring_id?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  mute_all_emails?: boolean | null;
  next_payment_date?: string | null;
  payment_interval?: RecurringInterval | null;
  payment_method?: PaymentMethodToken | null;
  payor?: Payor | null;
  prev_payment_date?: string | null;
  recurring_description?: string | null;
  recurring_name?: string | null;
  reference?: string | null;
  remaining_payments?: number | null;
  status?: RecurringStatus | null;
  total_amount_per_payment?: number | null;
};

export enum RecurringInterval {
  ANNUAL = 'ANNUAL',
  BI_ANNUAL = 'BI_ANNUAL',
  BI_WEEKLY = 'BI_WEEKLY',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  WEEKLY = 'WEEKLY',
}

export enum RecurringStatus {
  INSTRUMENT_FAILURE = 'INSTRUMENT_FAILURE',
  SUCCESS = 'SUCCESS',
  SYSTEM_FAILURE = 'SYSTEM_FAILURE',
}

export type RefundReason = {
  __typename: 'RefundReason';
  reason_code?: RefundReasonCode | null;
  reason_details?: string | null;
};

export enum RefundReasonCode {
  DUPLICATE = 'DUPLICATE',
  FRAUDULENT = 'FRAUDULENT',
  OTHER = 'OTHER',
  REQUESTED_BY_CUSTOMER = 'REQUESTED_BY_CUSTOMER',
}

export type Split = {
  __typename: 'Split';
  account_code?: string | null;
  amount: number;
  currency: string;
  created_at: string;
  id: string;
  merchant: ListMerchant;
  metadata: string;
  payment_method: PaymentMethodToken;
  reference?: string | null;
  settlement_batch?: number | null;
  transaction_id: string;
  updated_row_at: string;
};

export enum TransactionType {
  ACH_RETURN = 'ACH_RETURN',
  DEBIT = 'DEBIT',
  FAILURE = 'FAILURE',
  REVERSAL = 'REVERSAL',
}

export type DeviceInput = {
  device_id?: string | null;
  device_description: string;
  device_name: string;
  device_properties?: string | null;
  is_active?: boolean | null;
  merchant_uid: string;
  processor: string;
  processor_device_id: string;
};

export type Device = {
  __typename: 'Device';
  device_id?: string | null;
  device_description?: string | null;
  device_name?: string | null;
  device_properties?: string | null;
  is_active?: boolean | null;
  merchant_uid?: string | null;
  processor?: string | null;
  processor_device_id?: string | null;
};

export type InvoiceInput = {
  account_code?: string | null;
  currency: string;
  due_by?: string | null;
  fee_mode?: FeeMode | null;
  invoice_amount: number;
  invoice_date?: string | null;
  invoice_description?: string | null;
  invoice_name: string;
  merchant_uid: string;
  merchant_invoice_number?: string | null;
  metadata?: string | null;
  payor_id?: string | null;
  payor?: PayorInput | null;
  reference?: string | null;
  send_email?: boolean | null;
  settings?: InvoiceSettingsInput | null;
};

export type InvoiceSettingsInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsInput | null;
  is_secure?: boolean | null;
  require_payor_address?: boolean | null;
};

export type AcceptedPaymentMethodsInput = {
  ach?: boolean | null;
  card?: boolean | null;
  cash?: boolean | null;
};

export type MerchantUserInput = {
  email: string;
  first_name: string;
  last_name: string;
  phone?: string | null;
};

export type MerchantOnboardingInput = {
  banks?: Array<BusinessBankInput | null> | null;
  business?: BusinessInput | null;
  business_owners?: Array<BusinessOwnerInput | null> | null;
  country_code_of_operation?: CountryCodes_Alpha3 | null;
  merchant_uid: string;
  needs_docs?: boolean | null;
  ticket_id?: number | null;
  user_email?: string | null;
  user_full_name?: string | null;
};

export type BusinessBankInput = {
  account_number?: string | null;
  account_type?: BankAccountType | null;
  account_uses?: Array<BankAccountUse | null> | null;
  bank_code?: string | null;
  bank_name?: string | null;
  institution_number?: string | null;
  name?: string | null;
  transit_number?: string | null;
};

export enum BankAccountUse {
  CHARGEBACK = 'CHARGEBACK',
  CREDIT = 'CREDIT',
  DEBIT = 'DEBIT',
}

export type BusinessInput = {
  additional_underwriting_data?: AdditionalUnderwritingDataInput | null;
  underwriting_data?: UnderwritingDataInput | null;
};

export type AdditionalUnderwritingDataInput = {
  annual_ach_volume?: string | null;
  average_ach_transfer_amount?: number | null;
  average_card_transfer_amount?: number | null;
  business_description?: string | null;
  card_volume_distribution?: CardVolumeDistributionInput | null;
  credit_check_allowed?: boolean | null;
  credit_check_ip_address?: string | null;
  credit_check_timestamp?: string | null;
  credit_check_user_agent?: string | null;
  merchant_agreement_accepted?: boolean | null;
  merchant_agreement_ip_address?: string | null;
  merchant_agreement_timestamp?: string | null;
  merchant_agreement_user_agent?: string | null;
  statement_descriptor?: string | null;
  refund_policy?: RefundPolicy | null;
  volume_distribution_by_business_type?: VolumeDistributionByBusinessTypeInput | null;
};

export type CardVolumeDistributionInput = {
  card_present_percentage?: number | null;
  ecommerce_percentage?: number | null;
};

export enum RefundPolicy {
  EXCHANGE = 'EXCHANGE',
  NONE = 'NONE',
  OTHER = 'OTHER',
  THIRTY = 'THIRTY',
}

export type VolumeDistributionByBusinessTypeInput = {
  business_to_business_volume_percentage?: number | null;
  business_to_consumer_volume_percentage?: number | null;
  other_volume_percentage?: number | null;
};

export type UnderwritingDataInput = {
  annual_card_volume?: string | null;
  business_address?: AddressInput | null;
  has_accepted_credit_cards_previously?: boolean | null;
  business_name?: string | null;
  business_type?: BusinessType | null;
  delivery_0_days_percent?: number | null;
  delivery_1_to_7_days_percent?: number | null;
  delivery_8_to_14_days_percent?: number | null;
  delivery_15_to_30_days_percent?: number | null;
  delivery_over_30_days_percent?: number | null;
  doing_business_as?: string | null;
  incorporation_date?: string | null;
  incorporation_state?: UsStates | null;
  max_transaction_amount?: number | null;
  mcc?: string | null;
  phone?: string | null;
  prod_serv_sold?: string | null;
  ownership_type?: OwnershipType | null;
  tax_id?: string | null;
  url?: string | null;
};

export type AddressInput = {
  city?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  postal_code?: string | null;
  region?: string | null;
};

export enum BusinessType {
  CORP = 'CORP',
  GOV = 'GOV',
  INTERNATIONAL = 'INTERNATIONAL',
  LLC = 'LLC',
  PARTNERSHIP = 'PARTNERSHIP',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  TAX_EXEMPT = 'TAX_EXEMPT',
  TRUST = 'TRUST',
}

export enum UsStates {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum OwnershipType {
  PRIVATE = 'PRIVATE',
  PUBLIC = 'PUBLIC',
}

export type BusinessOwnerInput = {
  address?: AddressInput | null;
  dob?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  nationality?: CountryCodes_Alpha3 | null;
  owner_position?: OwnerPosition | null;
  percentage_ownership?: number | null;
  phone?: string | null;
  primary_owner?: boolean | null;
  tax_id?: string | null;
  title?: string | null;
  uid: string;
};

// https://en.wikipedia.org/wiki/ISO_3166-1_alpha-3
export enum CountryCodes_Alpha3 {
  AFG = 'AFG',
  ALA = 'ALA',
  ALB = 'ALB',
  DZA = 'DZA',
  ASM = 'ASM',
  AND = 'AND',
  AGO = 'AGO',
  AIA = 'AIA',
  ATA = 'ATA',
  ATG = 'ATG',
  ARG = 'ARG',
  ARM = 'ARM',
  ABW = 'ABW',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BHS = 'BHS',
  BHR = 'BHR',
  BGD = 'BGD',
  BRB = 'BRB',
  BLR = 'BLR',
  BEL = 'BEL',
  BLZ = 'BLZ',
  BEN = 'BEN',
  BMU = 'BMU',
  BTN = 'BTN',
  BOL = 'BOL',
  BES = 'BES',
  BIH = 'BIH',
  BWA = 'BWA',
  BVT = 'BVT',
  BRA = 'BRA',
  IOT = 'IOT',
  BRN = 'BRN',
  BGR = 'BGR',
  BFA = 'BFA',
  BDI = 'BDI',
  CPV = 'CPV',
  KHM = 'KHM',
  CMR = 'CMR',
  CAN = 'CAN',
  CYM = 'CYM',
  CAF = 'CAF',
  TCD = 'TCD',
  CHL = 'CHL',
  CHN = 'CHN',
  CXR = 'CXR',
  CCK = 'CCK',
  COL = 'COL',
  COM = 'COM',
  COG = 'COG',
  COD = 'COD',
  COK = 'COK',
  CRI = 'CRI',
  CIV = 'CIV',
  HRV = 'HRV',
  CUB = 'CUB',
  CUW = 'CUW',
  CYP = 'CYP',
  CZE = 'CZE',
  DNK = 'DNK',
  DJI = 'DJI',
  DMA = 'DMA',
  DOM = 'DOM',
  ECU = 'ECU',
  EGY = 'EGY',
  SLV = 'SLV',
  GNQ = 'GNQ',
  ERI = 'ERI',
  EST = 'EST',
  SWZ = 'SWZ',
  ETH = 'ETH',
  FLK = 'FLK',
  FRO = 'FRO',
  FJI = 'FJI',
  FIN = 'FIN',
  FRA = 'FRA',
  GUF = 'GUF',
  PYF = 'PYF',
  ATF = 'ATF',
  GAB = 'GAB',
  GMB = 'GMB',
  GEO = 'GEO',
  DEU = 'DEU',
  GHA = 'GHA',
  GIB = 'GIB',
  GRC = 'GRC',
  GRL = 'GRL',
  GRD = 'GRD',
  GLP = 'GLP',
  GUM = 'GUM',
  GTM = 'GTM',
  GGY = 'GGY',
  GIN = 'GIN',
  GNB = 'GNB',
  GUY = 'GUY',
  HTI = 'HTI',
  HMD = 'HMD',
  VAT = 'VAT',
  HND = 'HND',
  HKG = 'HKG',
  HUN = 'HUN',
  ISL = 'ISL',
  IND = 'IND',
  IDN = 'IDN',
  IRN = 'IRN',
  IRQ = 'IRQ',
  IRL = 'IRL',
  IMN = 'IMN',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JPN = 'JPN',
  JEY = 'JEY',
  JOR = 'JOR',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KIR = 'KIR',
  PRK = 'PRK',
  KOR = 'KOR',
  KWT = 'KWT',
  KGZ = 'KGZ',
  LAO = 'LAO',
  LVA = 'LVA',
  LBN = 'LBN',
  LSO = 'LSO',
  LBR = 'LBR',
  LBY = 'LBY',
  LIE = 'LIE',
  LTU = 'LTU',
  LUX = 'LUX',
  MAC = 'MAC',
  MDG = 'MDG',
  MWI = 'MWI',
  MYS = 'MYS',
  MDV = 'MDV',
  MLI = 'MLI',
  MLT = 'MLT',
  MHL = 'MHL',
  MTQ = 'MTQ',
  MRT = 'MRT',
  MUS = 'MUS',
  MYT = 'MYT',
  MEX = 'MEX',
  FSM = 'FSM',
  MDA = 'MDA',
  MCO = 'MCO',
  MNG = 'MNG',
  MNE = 'MNE',
  MSR = 'MSR',
  MAR = 'MAR',
  MOZ = 'MOZ',
  MMR = 'MMR',
  NAM = 'NAM',
  NRU = 'NRU',
  NPL = 'NPL',
  NLD = 'NLD',
  NCL = 'NCL',
  NZL = 'NZL',
  NIC = 'NIC',
  NER = 'NER',
  NGA = 'NGA',
  NIU = 'NIU',
  NFK = 'NFK',
  MKD = 'MKD',
  MNP = 'MNP',
  NOR = 'NOR',
  OMN = 'OMN',
  PAK = 'PAK',
  PLW = 'PLW',
  PSE = 'PSE',
  PAN = 'PAN',
  PNG = 'PNG',
  PRY = 'PRY',
  PER = 'PER',
  PHL = 'PHL',
  PCN = 'PCN',
  POL = 'POL',
  PRT = 'PRT',
  PRI = 'PRI',
  QAT = 'QAT',
  REU = 'REU',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  BLM = 'BLM',
  SHN = 'SHN',
  KNA = 'KNA',
  LCA = 'LCA',
  MAF = 'MAF',
  SPM = 'SPM',
  VCT = 'VCT',
  WSM = 'WSM',
  SMR = 'SMR',
  STP = 'STP',
  SAU = 'SAU',
  SEN = 'SEN',
  SRB = 'SRB',
  SYC = 'SYC',
  SLE = 'SLE',
  SGP = 'SGP',
  SXM = 'SXM',
  SVK = 'SVK',
  SVN = 'SVN',
  SLB = 'SLB',
  SOM = 'SOM',
  ZAF = 'ZAF',
  SGS = 'SGS',
  SSD = 'SSD',
  ESP = 'ESP',
  LKA = 'LKA',
  SDN = 'SDN',
  SUR = 'SUR',
  SJM = 'SJM',
  SWE = 'SWE',
  CHE = 'CHE',
  SYR = 'SYR',
  TWN = 'TWN',
  TJK = 'TJK',
  TZA = 'TZA',
  THA = 'THA',
  TLS = 'TLS',
  TGO = 'TGO',
  TKL = 'TKL',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TKM = 'TKM',
  TCA = 'TCA',
  TUV = 'TUV',
  UGA = 'UGA',
  UKR = 'UKR',
  ARE = 'ARE',
  GBR = 'GBR',
  USA = 'USA',
  UMI = 'UMI',
  URY = 'URY',
  UZB = 'UZB',
  VUT = 'VUT',
  VEN = 'VEN',
  VNM = 'VNM',
  VGB = 'VGB',
  VIR = 'VIR',
  WLF = 'WLF',
  ESH = 'ESH',
  YEM = 'YEM',
  ZMB = 'ZMB',
  ZWE = 'ZWE',
}

export enum OwnerPosition {
  CEO = 'CEO',
  CFO = 'CFO',
  COO = 'COO',
  DIRECTOR = 'DIRECTOR',
  MEMBER_LLC = 'MEMBER_LLC',
  OTHER = 'OTHER',
  OWNER = 'OWNER',
  PARTNER = 'PARTNER',
  PRESIDENT = 'PRESIDENT',
  SECRETARY = 'SECRETARY',
  SOLE_PROPRIETOR = 'SOLE_PROPRIETOR',
  TREASURER = 'TREASURER',
  VICE_PRESIDENT = 'VICE_PRESIDENT',
}

export type SupportNoteInput = {
  body?: string | null;
  support_docs?: Array<DocumentInput | null> | null;
};

export type DocumentInput = {
  // Base 64 encoded file data
  body: string;
  doc_type: SupportDocumentType;
  // The mime type (will be used as is)
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  mime_type: string;
  // The name of the file
  name: string;
};

export enum SupportDocumentType {
  BANK_STATEMENT = 'BANK_STATEMENT',
  BUSINESS_ADDRESS_VERIFICATION = 'BUSINESS_ADDRESS_VERIFICATION',
  BUSINESS_REGISTRATION = 'BUSINESS_REGISTRATION',
  DRIVERS_LICENSE_BACK = 'DRIVERS_LICENSE_BACK',
  DRIVERS_LICENSE_FRONT = 'DRIVERS_LICENSE_FRONT',
  IDENTIFICATION_CARD_BACK = 'IDENTIFICATION_CARD_BACK',
  IDENTIFICATION_CARD_FRONT = 'IDENTIFICATION_CARD_FRONT',
  PASSPORT = 'PASSPORT',
  PCI_DOCUMENT = 'PCI_DOCUMENT',
  OTHER = 'OTHER',
  TAX_DOCUMENT = 'TAX_DOCUMENT',
}

export type NativeAppInput = {
  app_id: string;
  description?: string | null;
  device_check_key?: string | null;
  device_check_kid?: string | null;
  merchant_uid: string;
  platform: NativeAppPlatform;
};

export enum NativeAppPlatform {
  ANDROID = 'ANDROID',
  APPLE = 'APPLE',
}

export enum NotificationContextId {
  DISPUTE_CHANGE = 'DISPUTE_CHANGE',
  DISPUTE_EVIDENCE_DELETED = 'DISPUTE_EVIDENCE_DELETED',
  DISPUTE_EVIDENCE_SENT = 'DISPUTE_EVIDENCE_SENT',
  DISPUTE_EVIDENCE_UPLOADED = 'DISPUTE_EVIDENCE_UPLOADED',
}

export type Notification = {
  __typename: 'Notification';
  context_id: NotificationContextId;
  error?: string | null;
  merchant_uid: string;
  primary_value?: string | null;
  secondary_value?: string | null;
};

export type OfflineTransactionInput = {
  amount: number;
  invoice_id: string;
  note?: string | null;
  transaction_date: string;
  type: OfflineTransactionType;
};

export type OneTimePayment = {
  __typename: 'OneTimePayment';
  amount?: number | null;
  card_brand?: string | null;
  created_at?: string | null;
  currency?: string | null;
  failure_reason?: string | null;
  last_four?: string | null;
  service_fee?: number | null;
  status?: TransactionStatus | null;
  transaction_id?: string | null;
};

export type PaymentLinkInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
  account_code?: string | null;
  amount?: number | null;
  amount_is_variable?: boolean | null;
  call_to_action?: CallToActionType | null;
  currency?: string | null;
  custom_success_message?: string | null;
  fee_mode?: FeeMode | null;
  link_name: string;
  max_amount?: number | null;
  merchant_uid: string;
  min_amount?: number | null;
  payment_description?: string | null;
  payment_name: string;
  redirect_url?: string | null;
  require_phone?: boolean | null;
};

export enum AcceptedPaymentMethodsEnum {
  ALL = 'ALL',
  NOT_ACH = 'NOT_ACH',
  NOT_CARD = 'NOT_CARD',
  NOT_CASH = 'NOT_CASH',
  ONLY_ACH = 'ONLY_ACH',
  ONLY_CARD = 'ONLY_CARD',
  ONLY_CASH = 'ONLY_CASH',
}

export enum CallToActionType {
  BOOK = 'BOOK',
  DONATE = 'DONATE',
  PAY = 'PAY',
}

export type PaymentLink = {
  __typename: 'PaymentLink';
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
  account_code?: string | null;
  amount?: number | null;
  amount_is_variable?: boolean | null;
  call_to_action?: CallToActionType | null;
  created_date?: string | null;
  currency?: string | null;
  custom_success_message?: string | null;
  fee_mode?: FeeMode | null;
  is_active?: boolean | null;
  link_id?: string | null;
  link_name?: string | null;
  link_url?: string | null;
  max_amount?: number | null;
  merchant_uid?: string | null;
  min_amount?: number | null;
  payment_name?: string | null;
  payment_description?: string | null;
  redirect_url?: string | null;
  require_phone?: boolean | null;
};

export type RecurringPaymentInput = {
  account_code?: string | null;
  amount: number;
  fee_mode?: FeeMode | null;
  first_payment_date?: string | null;
  merchant_uid: string;
  metadata?: string | null;
  mute_all_emails?: boolean | null;
  payment_count?: number | null;
  payment_interval: RecurringInterval;
  payment_method_id: string;
  payor?: PayorInput | null;
  payor_id?: string | null;
  recurring_description?: string | null;
  recurring_name: string;
  reference?: string | null;
};

export type RefundReasonInput = {
  reason_code: RefundReasonCode;
  reason_details?: string | null;
};

export type ReversalResponse = {
  __typename: 'ReversalResponse';
  is_void: boolean;
  transaction_id?: string | null;
};

export type UserInput = {
  bypass_merchant_validation?: boolean | null;
  email: string;
  first_name: string;
  last_name: string;
  merchant_uid?: string | null;
  phone?: string | null;
  user_pool: UserPool;
};

export enum UserPool {
  MERCHANT = 'MERCHANT',
  PARTNER = 'PARTNER',
  SYSTEM = 'SYSTEM',
}

export type User = {
  __typename: 'User';
  email?: string | null;
  full_name?: string | null;
  phone?: string | null;
  user_status?: string | null;
  username?: string | null;
};

export type UserInputV2 = {
  bypass_merchant_validation?: boolean | null;
  email: string;
  first_name: string;
  last_name: string;
  merchant_uid?: string | null;
  user_pool: UserPool;
  permissions?: string | null;
};

export enum TransactionReviewAction {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT',
}

export enum MetadataAssociate {
  AUTHORIZATION = 'AUTHORIZATION',
  INVOICE = 'INVOICE',
  MERCHANT = 'MERCHANT',
  PAYMENT_INTENT = 'PAYMENT_INTENT',
  PAYMENT_METHOD_TOKEN = 'PAYMENT_METHOD_TOKEN',
  PAYMENT_SESSION = 'PAYMENT_SESSION',
  PAYOR = 'PAYOR',
  RECURRING = 'RECURRING',
  TRANSACTION = 'TRANSACTION',
  SALE = 'SALE',
  SPLIT = 'SPLIT',
}

export type FeeMatrixInput = {
  ach?: AchFeeInput | null;
  ach_return_fee?: number | null;
  ach_return_disputed_fee?: number | null;
  business_credit?: CardFeeInput | null;
  business_debit?: CardFeeInput | null;
  amex?: CardBrandFeeInput | null;
  card?: CardFeeInput | null;
  card_account_updater?: number | null;
  cash?: number | null;
  chargeback_fee?: number | null;
  credit_card?: CardFeeInput | null;
  debit_card?: CardFeeInput | null;
  discover?: CardBrandFeeInput | null;
  interchange_plus?: boolean | null;
  international_card_basis?: number | null;
  mastercard?: CardBrandFeeInput | null;
  merchant_uid: string;
  prepaid_card?: CardFeeInput | null;
  service_fee_enabled?: boolean | null;
  visa?: CardBrandFeeInput | null;
};

export type AchFeeInput = {
  merchant_fee: AchMerchantFeeInput;
  service_fee: AchServiceFeeInput;
};

export type AchMerchantFeeInput = {
  basis_points?: number | null;
  fixed?: number | null;
  max_fee?: number | null;
};

export type AchServiceFeeInput = {
  basis_points: number;
  fixed: number;
  max_fee?: number | null;
  min_fee?: number | null;
};

export type CardFeeInput = {
  merchant_fee: CardMerchantFeeInput;
  service_fee: CardServiceFeeInput;
};

export type CardMerchantFeeInput = {
  basis_points: number;
  fixed: number;
};

export type CardServiceFeeInput = {
  basis_points: number;
  fixed: number;
  min_fee?: number | null;
};

export type CardBrandFeeInput = {
  merchant_fee: CardMerchantFeeInput;
};

export type FeeMatrix = {
  __typename: 'FeeMatrix';
  ach: AchFee;
  ach_return_fee: number;
  ach_return_disputed_fee: number;
  business_credit?: CardFee | null;
  business_debit?: CardFee | null;
  amex?: CardBrandFee | null;
  card: CardFee;
  card_account_updater: number;
  cash: number;
  chargeback_fee: number;
  credit_card?: CardFee | null;
  debit_card?: CardFee | null;
  discover?: CardBrandFee | null;
  interchange_plus: boolean;
  international_card_basis: number;
  mastercard?: CardBrandFee | null;
  merchant_uid: string;
  prepaid_card?: CardFee | null;
  service_fee_enabled: boolean;
  visa?: CardBrandFee | null;
};

export type AchFee = {
  __typename: 'AchFee';
  merchant_fee?: AchMerchantFee | null;
  service_fee?: AchServiceFee | null;
};

export type AchMerchantFee = {
  __typename: 'AchMerchantFee';
  basis_points: number;
  fixed: number;
  max_fee?: number | null;
};

export type AchServiceFee = {
  __typename: 'AchServiceFee';
  basis_points?: number | null;
  fixed?: number | null;
  max_fee?: number | null;
  min_fee?: number | null;
};

export type CardFee = {
  __typename: 'CardFee';
  merchant_fee?: CardMerchantFee | null;
  service_fee?: CardServiceFee | null;
};

export type CardMerchantFee = {
  __typename: 'CardMerchantFee';
  basis_points?: number | null;
  fixed?: number | null;
};

export type CardServiceFee = {
  __typename: 'CardServiceFee';
  basis_points?: number | null;
  fixed?: number | null;
  min_fee?: number | null;
};

export type CardBrandFee = {
  __typename: 'CardBrandFee';
  merchant_fee?: CardMerchantFee | null;
};

export type InvoiceUpdateInput = {
  account_code?: string | null;
  currency?: string | null;
  due_by?: string | null;
  fee_mode?: FeeMode | null;
  invoice_amount?: number | null;
  invoice_date?: string | null;
  invoice_name?: string | null;
  invoice_description?: string | null;
  merchant_invoice_number?: string | null;
  reference?: string | null;
  send_email?: boolean | null;
  settings?: InvoiceSettingsInput | null;
};

export type MerchantSettingsInput = {
  contact_email?: string | null;
  contact_phone?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  tiktok?: string | null;
  twitter?: string | null;
  website?: string | null;
};

export type UpdatePaymentLinkInput = {
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
  account_code?: string | null;
  amount?: number | null;
  amount_is_variable?: boolean | null;
  call_to_action?: CallToActionType | null;
  currency?: string | null;
  custom_success_message?: string | null;
  fee_mode?: FeeMode | null;
  is_active?: boolean | null;
  link_id: string;
  link_name?: string | null;
  max_amount?: number | null;
  merchant_uid: string;
  min_amount?: number | null;
  payment_description?: string | null;
  payment_name?: string | null;
  redirect_url?: string | null;
  require_phone?: boolean | null;
};

export type PayorData = {
  address_line1?: string | null;
  address_line2?: string | null;
  city?: string | null;
  country?: string | null;
  email?: string | null;
  full_name?: string | null;
  phone?: string | null;
  postal_code?: string | null;
  region?: string | null;
};

export type UpdateRecurringPaymentInput = {
  mute_all_emails?: boolean | null;
  pay_all_missed_payments?: boolean | null;
  payment_method_id?: string | null;
  recurring_id: string;
};

export type WalletAppleInput = {
  add_domain_names?: Array<string | null> | null;
  delete_domain_names?: Array<string | null> | null;
  partner_merchant_name: string;
  partner_internal_merchant_identifier: string;
};

export type WalletRegistrationAppleResult = {
  __typename: 'WalletRegistrationAppleResult';
  successful_domain_names: Array<string | null>;
  failed_domain_names: Array<string | null>;
};

export type SqlQuery = {
  query_list?: Array<QueryPair | null> | null;
  sort_list?: Array<SortPair | null> | null;
};

export type QueryPair = {
  conjunctive_operator?: ConjunctiveOperator | null;
  in_values?: Array<string | null> | null;
  key?: string | null;
  operator?: Operator | null;
  query_group?: Array<QueryPair | null> | null;
  value?: string | null;
};

export enum ConjunctiveOperator {
  AND_NEXT = 'AND_NEXT',
  NONE_NEXT = 'NONE_NEXT',
  OR_NEXT = 'OR_NEXT',
}

export enum Operator {
  EQUAL = 'EQUAL',
  EQUAL_FALSE = 'EQUAL_FALSE',
  EQUAL_TRUE = 'EQUAL_TRUE',
  GREATER_EQUAL = 'GREATER_EQUAL',
  GREATER_THAN = 'GREATER_THAN',
  IN_LIST = 'IN_LIST',
  IS_NOT_NULL = 'IS_NOT_NULL',
  IS_NULL = 'IS_NULL',
  LESS_EQUAL = 'LESS_EQUAL',
  LESS_THAN = 'LESS_THAN',
  LIKE = 'LIKE',
  NOT_EQUAL = 'NOT_EQUAL',
  NOT_IN_LIST = 'NOT_IN_LIST',
  NOT_LIKE = 'NOT_LIKE',
}

export type SortPair = {
  direction?: SortDirection | null;
  key?: string | null;
};

export enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum MoveDirection {
  BACKWARD = 'BACKWARD',
  FORWARD = 'FORWARD',
}

export type Authorizations = {
  __typename: 'Authorizations';
  items?: Array<Authorization | null> | null;
  total_row_count?: number | null;
};

export type BackofficeKeyData = {
  __typename: 'BackofficeKeyData';
  created_date?: string | null;
  enabled?: boolean | null;
  entity_level?: BackofficeKeyEntityLevel | null;
  key_name?: string | null;
  last_accessed_date?: string | null;
  merchant_uid?: string | null;
};

export type DashboardPayments = {
  __typename: 'DashboardPayments';
  gross_amount?: Array<string | null> | null;
};

export type DashboardPaymentMethod = {
  __typename: 'DashboardPaymentMethod';
  card?: string | null;
  ach?: string | null;
  cash?: string | null;
};

export type DashboardFundingTotals = {
  __typename: 'DashboardFundingTotals';
  payments?: string | null;
  refunds?: string | null;
  fees?: string | null;
  ach_returns?: string | null;
  disputes?: string | null;
  adjustments?: string | null;
};

export type DashboardAuthRate = {
  __typename: 'DashboardAuthRate';
  card?: Array<number | null> | null;
  ach?: Array<number | null> | null;
};

export type DashboardDisputeRate = {
  __typename: 'DashboardDisputeRate';
  card?: Array<number | null> | null;
  ach?: Array<number | null> | null;
};

export type PaymentParameters = {
  __typename: 'PaymentParameters';
  denied_after_date?: string | null;
  denied_before_date?: string | null;
  enabled: boolean;
  expires_in?: number | null;
  maximum_amount?: number | null;
  maximum_occurrence?: number | null;
  minimum_amount?: number | null;
  payment_parameters_name?: string | null;
  payment_parameters_type: string;
  recurrence_period?: string | null;
  validation_link_url?: string | null;
};

export type Devices = {
  __typename: 'Devices';
  items?: Array<Device | null> | null;
  total_row_count?: number | null;
};

export type DisputeEvidence = {
  __typename: 'DisputeEvidence';
  dispute_id?: string | null;
  evidence_date?: string | null;
  filename?: string | null;
  merchant_uid?: string | null;
  status?: EvidenceStatus | null;
  updated_date?: string | null;
};

export enum EvidenceStatus {
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  SUCCEEDED = 'SUCCEEDED',
}

export enum AwsS3Action {
  DELETE = 'DELETE',
  GET = 'GET',
  PUT = 'PUT',
}

export type Disputes = {
  __typename: 'Disputes';
  items?: Array<Dispute | null> | null;
  total_row_count?: number | null;
};

export type Dispute = {
  __typename: 'Dispute';
  amount?: number | null;
  dispute_date?: string | null;
  dispute_id?: string | null;
  evidence_last_send_date?: string | null;
  expiration_date?: string | null;
  merchant_uid?: string | null;
  status?: DisputeStatus | null;
  reason?: DisputeReason | null;
  reason_message?: string | null;
  settlement_deposit_batch?: string | null;
  settlement_withdrawal_batch?: string | null;
  transaction?: Transaction | null;
  updated_date?: string | null;
  updated_row_at?: string | null;
};

export enum DisputeReason {
  CLERICAL = 'CLERICAL',
  FRAUD = 'FRAUD',
  INQUIRY = 'INQUIRY',
  QUALITY = 'QUALITY',
  TECHNICAL = 'TECHNICAL',
}

export type CSVInput = {
  locale?: string | null;
  merchant_uid?: string | null;
  report_name_prefix?: string | null;
  settlement_batch?: number | null;
  source?: string | null;
};

export type CSVOutput = {
  __typename: 'CSVOutput';
  bucket_name?: string | null;
  filepaths?: Array<string | null> | null;
};

export type HostedCheckout = {
  __typename: 'HostedCheckout';
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
  account_code?: string | null;
  amount?: number | null;
  api_key?: string | null;
  call_to_action?: CallToActionType | null;
  checkout_type?: string | null;
  country_code?: string | null;
  currency?: string | null;
  fee_mode?: FeeMode | null;
  invoice_id?: string | null;
  is_active?: boolean | null;
  merchant_name?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  origin?: string | null;
  payment_description?: string | null;
  payment_name?: string | null;
  payment_parameters?: string | null;
  payor?: Payor | null;
  recurring_id?: string | null;
  require_phone?: boolean | null;
};

export type InvoiceForCheckout = {
  __typename: 'InvoiceForCheckout';
  account_code?: string | null;
  api_key?: string | null;
  country_code?: string | null;
  currency?: string | null;
  fee_mode?: FeeMode | null;
  invoice_amount?: number | null;
  invoice_description?: string | null;
  invoice_id?: string | null;
  invoice_name?: string | null;
  merchant_name?: string | null;
  merchant_uid?: string | null;
  payor?: Payor | null;
  reference?: string | null;
  settings?: InvoiceSettings | null;
  status?: InvoiceStatus | null;
  total_paid_amount?: number | null;
};

export type Invoices = {
  __typename: 'Invoices';
  items?: Array<Invoice | null> | null;
  total_row_count?: number | null;
};

export type Merchant = {
  __typename: 'Merchant';
  ach_active?: boolean | null;
  api_key?: string | null;
  card_active?: boolean | null;
  cash_active?: boolean | null;
  country_code?: string | null;
  fee_matrix?: FeeMatrix | null;
  fee_model?: FeeModel | null;
  is_system?: boolean | null;
  merchant_name?: string | null;
  merchant_uid?: string | null;
  metadata?: string | null;
  parent_merchant_uid?: string | null;
  settings?: MerchantSettings | null;
  submitted_onboarding?: boolean | null;
};

export type FeeModel = {
  __typename: 'FeeModel';
  interchange_plus?: boolean | null;
  merchant_fee?: FeeModelDetail | null;
  service_fee?: FeeModelDetail | null;
  service_fee_min?: FeeModelDetail | null;
};

export type FeeModelDetail = {
  __typename: 'FeeModelDetail';
  ach_basis?: number | null;
  ach_fixed?: number | null;
  card_basis?: number | null;
  card_fixed?: number | null;
};

export type MerchantSettings = {
  __typename: 'MerchantSettings';
  contact_email?: string | null;
  contact_phone?: string | null;
  facebook?: string | null;
  instagram?: string | null;
  linkedin?: string | null;
  tiktok?: string | null;
  twitter?: string | null;
  website?: string | null;
};

export type MerchantOnboarding = {
  __typename: 'MerchantOnboarding';
  banks?: Array<BusinessBank | null> | null;
  business?: Business | null;
  business_owners?: Array<BusinessOwner | null> | null;
  country_code_of_operation?: CountryCodes_Alpha3 | null;
  is_locked?: boolean | null;
  merchant_uid: string;
  needs_docs?: boolean | null;
  ticket_id?: number | null;
  user_email?: string | null;
  user_full_name?: string | null;
};

export type BusinessBank = {
  __typename: 'BusinessBank';
  account_number?: string | null;
  account_type?: BankAccountType | null;
  account_uses?: Array<BankAccountUse | null> | null;
  bank_code?: string | null;
  bank_name?: string | null;
  institution_number?: string | null;
  name?: string | null;
  transit_number?: string | null;
};

export type Business = {
  __typename: 'Business';
  additional_underwriting_data?: AdditionalUnderwritingData | null;
  underwriting_data?: UnderwritingData | null;
};

export type AdditionalUnderwritingData = {
  __typename: 'AdditionalUnderwritingData';
  annual_ach_volume?: string | null;
  average_ach_transfer_amount?: number | null;
  average_card_transfer_amount?: number | null;
  business_description?: string | null;
  card_volume_distribution?: CardVolumeDistribution | null;
  credit_check_allowed?: boolean | null;
  credit_check_ip_address?: string | null;
  credit_check_timestamp?: string | null;
  credit_check_user_agent?: string | null;
  merchant_agreement_accepted?: boolean | null;
  merchant_agreement_ip_address?: string | null;
  merchant_agreement_timestamp?: string | null;
  merchant_agreement_user_agent?: string | null;
  statement_descriptor?: string | null;
  refund_policy?: RefundPolicy | null;
  volume_distribution_by_business_type?: VolumeDistributionByBusinessType | null;
};

export type CardVolumeDistribution = {
  __typename: 'CardVolumeDistribution';
  card_present_percentage?: number | null;
  ecommerce_percentage?: number | null;
};

export type VolumeDistributionByBusinessType = {
  __typename: 'VolumeDistributionByBusinessType';
  business_to_business_volume_percentage?: number | null;
  business_to_consumer_volume_percentage?: number | null;
  other_volume_percentage?: number | null;
};

export type UnderwritingData = {
  __typename: 'UnderwritingData';
  annual_card_volume?: string | null;
  business_address?: Address | null;
  has_accepted_credit_cards_previously?: boolean | null;
  business_name?: string | null;
  business_type?: BusinessType | null;
  delivery_0_days_percent?: number | null;
  delivery_1_to_7_days_percent?: number | null;
  delivery_8_to_14_days_percent?: number | null;
  delivery_15_to_30_days_percent?: number | null;
  delivery_over_30_days_percent?: number | null;
  doing_business_as?: string | null;
  incorporation_date?: string | null;
  incorporation_state?: UsStates | null;
  max_transaction_amount?: number | null;
  mcc?: string | null;
  phone?: string | null;
  prod_serv_sold?: string | null;
  ownership_type?: OwnershipType | null;
  tax_id?: string | null;
  url?: string | null;
};

export type Address = {
  __typename: 'Address';
  city?: string | null;
  country?: string | null;
  line1?: string | null;
  line2?: string | null;
  postal_code?: string | null;
  region?: string | null;
};

export type BusinessOwner = {
  __typename: 'BusinessOwner';
  address?: Address | null;
  dob?: string | null;
  email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  middle_name?: string | null;
  nationality?: CountryCodes_Alpha3 | null;
  owner_position?: OwnerPosition | null;
  percentage_ownership?: number | null;
  phone?: string | null;
  primary_owner?: boolean | null;
  tax_id?: string | null;
  title?: string | null;
  uid: string;
};

export type Merchants = {
  __typename: 'Merchants';
  items?: Array<ListMerchant | null> | null;
  total_row_count?: number | null;
};

export type MissedRecurringPaymentData = {
  __typename: 'MissedRecurringPaymentData';
  fee?: number | null;
  number_of_payments_missed?: number | null;
  total_amount_owed?: number | null;
};

export type NativeApp = {
  __typename: 'NativeApp';
  app_id?: string | null;
  apple_pay_expiration_date?: string | null;
  description?: string | null;
  device_check_key?: string | null;
  device_check_kid?: string | null;
  merchant_uid?: string | null;
  platform?: NativeAppPlatform | null;
};

export type PaymentLinkForCheckout = {
  __typename: 'PaymentLinkForCheckout';
  accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
  account_code?: string | null;
  amount?: number | null;
  amount_is_variable?: boolean | null;
  api_key?: string | null;
  call_to_action?: CallToActionType | null;
  country_code?: string | null;
  currency?: string | null;
  custom_success_message?: string | null;
  fee_mode?: FeeMode | null;
  is_active?: boolean | null;
  max_amount?: number | null;
  merchant_name?: string | null;
  merchant_uid?: string | null;
  min_amount?: number | null;
  payment_description?: string | null;
  payment_name?: string | null;
  redirect_url?: string | null;
  require_phone?: boolean | null;
};

export type PaymentLinks = {
  __typename: 'PaymentLinks';
  items?: Array<PaymentLink | null> | null;
  total_row_count?: number | null;
};

export type PaymentMethodTokens = {
  __typename: 'PaymentMethodTokens';
  items?: Array<PaymentMethodToken | null> | null;
  total_row_count?: number | null;
};

export type Payors = {
  __typename: 'Payors';
  items?: Array<Payor | null> | null;
  total_row_count?: number | null;
};

export type RecurringUpdateDetails = {
  __typename: 'RecurringUpdateDetails';
  amount?: number | null;
  api_key?: string | null;
  hash?: string | null;
  merchant_name?: string | null;
  payor_id?: string | null;
  payor_name?: string | null;
  recurring_description?: string | null;
  recurring_name?: string | null;
};

export type RecurringPayments = {
  __typename: 'RecurringPayments';
  items?: Array<RecurringPayment | null> | null;
  total_row_count?: number | null;
};

export type ServiceFeeCalculation = {
  __typename: 'ServiceFeeCalculation';
  fee_limit_reached?: boolean | null;
  fee?: number | null;
  total?: number | null;
  adjusted_total?: number | null;
};

export type ServiceFeeAmount = {
  __typename: 'ServiceFeeAmount';
  ach?: ServiceFeeCalculation | null;
  card?: ServiceFeeCalculation | null;
};

export type Settlements = {
  __typename: 'Settlements';
  items?: Array<Settlement | null> | null;
  total_row_count?: number | null;
};

export type Settlement = {
  __typename: 'Settlement';
  currency?: string | null;
  gross_amount?: number | null;
  gross_amount_64bit?: string | null;
  merchant_uid?: string | null;
  net_amount?: number | null;
  net_amount_64bit?: string | null;
  settlement_batch?: number | null;
  settlement_date?: string | null;
  status?: string | null;
  transaction_debit_count?: number | null;
  transaction_dispute_count?: number | null;
  transaction_reversal_count?: number | null;
  transfer_date?: string | null;
  total_adjustments?: number | null;
  total_adjustments_64bit?: string | null;
  total_fees?: number | null;
  total_fees_64bit?: string | null;
  updated_row_at?: string | null;
};

export type Splits = {
  __typename: 'Splits';
  items?: Array<Split | null> | null;
  total_row_count?: number | null;
};

export type SupportConversation = {
  __typename: 'SupportConversation';
  support_notes?: Array<SupportNote | null> | null;
  ticket_id: string;
};

export type SupportNote = {
  __typename: 'SupportNote';
  body?: string | null;
  support_docs?: Array<Document | null> | null;
};

export type Document = {
  __typename: 'Document';
  attachment_id: string;
  doc_type: SupportDocumentType;
  name: string;
};

export type Transactions = {
  __typename: 'Transactions';
  items?: Array<Transaction | null> | null;
  total_row_count?: number | null;
};

export type UserV2 = {
  __typename: 'UserV2';
  user_id?: string | null;
  email?: string | null;
  full_name?: string | null;
  user_pool_id?: UserPool | null;
  merchant_uid?: string | null;
  permissions?: string | null;
};

export type WalletResult = WalletApple;

export type WalletApple = {
  __typename: 'WalletApple';
  domain_names: Array<string | null>;
  registered_domain_names: Array<string | null>;
  partner_internal_merchant_identifier: string;
  partner_merchant_name: string;
  wallet_type: WalletType;
};

export type Webhook = {
  __typename: 'Webhook';
  endpoint: string;
  // Url the event will be sent to
  is_active: boolean;
  // Whether the webhook is active (receiving notifications)
  name: string;
};

export enum WebhookNotificationResult {
  FAILURE = 'FAILURE',
  IGNORED = 'IGNORED',
  SUCCESS = 'SUCCESS',
}

export type WebhookEvents = {
  __typename: 'WebhookEvents';
  events: Array<WebhookEvent | null>;
  // List of events that have been sent to the endpoint
  last_evaluated_key?: string | null;
};

export type WebhookEvent = {
  __typename: 'WebhookEvent';
  endpoint: string;
  // URL the event was sent to
  error?: string | null;
  // Error message if the event failed
  event: string;
  // Type of event that was sent
  finished_at: string;
  // Time the last response was received (or last attempt was made)
  id: string;
  // Unique ID for the event
  request: string;
  // Request that was sent to the endpoint
  response?: string | null;
  // Data from the last response, null if no response was received
  result: WebhookNotificationResult;
  // Final result of the event
  started_at: string;
  // Time the first request was made
  status_code?: number | null;
};

export type CreateAuthorizationMutationVariables = {
  merchant_uid: string;
  sale_id?: string | null;
  amount: number;
  payment_method_id?: string | null;
  payment_method?: PaymentMethodInput | null;
  invoice_id?: string | null;
  fee?: number | null;
  fee_mode?: FeeMode | null;
  account_code?: string | null;
  reference?: string | null;
  metadata?: string | null;
  health_expense_type?: HealthExpenseType | null;
  additional_purchase_data?: AdditionalPurchaseDataInput | null;
  one_time_use_token?: boolean | null;
};

export type CreateAuthorizationMutation = {
  // Create an authorization
  createAuthorization: {
    __typename: 'Authorization';
    account_code?: string | null;
    additional_purchase_data?: {
      __typename: 'AdditionalPurchaseData';
      level3_data_line_item?: Array<{
        __typename: 'Level3DataLineItem';
        item_code?: string | null;
        item_description?: string | null;
        item_qty_exp?: number | null;
        prod_code?: string | null;
        qty?: number | null;
        tax_amount?: number | null;
        tax_ind?: TaxIndicatorType | null;
        tax_rate?: number | null;
        tax_rt_exp?: number | null;
        tax_type_id?: TaxType | null;
        unit_cost?: number | null;
        unit_of_msure?: string | null;
      } | null> | null;
      level3_data_summary?: {
        __typename: 'Level3DataSummary';
        dest_postal_code?: string | null;
        discnt_amt?: number | null;
        duty_amt?: number | null;
        frght_amt?: number | null;
        order_num?: string | null;
        prod_desc?: Array<string | null> | null;
        purch_idfr?: string | null;
        tax_amt?: number | null;
        tax_ind?: TaxIndicatorType | null;
      } | null;
    } | null;
    amount: number;
    authorization_date: string;
    authorization_id: string;
    avs_status?: string | null;
    captured_amount?: number | null;
    currency: string;
    device_id?: string | null;
    expiration_date?: string | null;
    failure_reasons?: Array<string | null> | null;
    fee_mode: FeeMode;
    fees: number;
    invoice?: {
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null;
    merchant_uid: string;
    metadata?: string | null;
    payment_method: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    };
    processor?: string | null;
    reference?: string | null;
    sale_id?: string | null;
    status: AuthorizationStatus;
    timezone?: string | null;
    transaction_id?: string | null;
    updated_row_at?: string | null;
  };
};

export type CreateWalletAuthorizationMutationVariables = {
  merchant_uid: string;
  sale_id?: string | null;
  digital_wallet_payload: string;
  payor_id?: string | null;
  payor?: PayorInput | null;
  invoice_id?: string | null;
  account_code?: string | null;
  reference?: string | null;
  metadata?: string | null;
  health_expense_type?: HealthExpenseType | null;
};

export type CreateWalletAuthorizationMutation = {
  // Create an authorization for a digital wallet
  createWalletAuthorization: {
    __typename: 'Authorization';
    account_code?: string | null;
    additional_purchase_data?: {
      __typename: 'AdditionalPurchaseData';
      level3_data_line_item?: Array<{
        __typename: 'Level3DataLineItem';
        item_code?: string | null;
        item_description?: string | null;
        item_qty_exp?: number | null;
        prod_code?: string | null;
        qty?: number | null;
        tax_amount?: number | null;
        tax_ind?: TaxIndicatorType | null;
        tax_rate?: number | null;
        tax_rt_exp?: number | null;
        tax_type_id?: TaxType | null;
        unit_cost?: number | null;
        unit_of_msure?: string | null;
      } | null> | null;
      level3_data_summary?: {
        __typename: 'Level3DataSummary';
        dest_postal_code?: string | null;
        discnt_amt?: number | null;
        duty_amt?: number | null;
        frght_amt?: number | null;
        order_num?: string | null;
        prod_desc?: Array<string | null> | null;
        purch_idfr?: string | null;
        tax_amt?: number | null;
        tax_ind?: TaxIndicatorType | null;
      } | null;
    } | null;
    amount: number;
    authorization_date: string;
    authorization_id: string;
    avs_status?: string | null;
    captured_amount?: number | null;
    currency: string;
    device_id?: string | null;
    expiration_date?: string | null;
    failure_reasons?: Array<string | null> | null;
    fee_mode: FeeMode;
    fees: number;
    invoice?: {
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null;
    merchant_uid: string;
    metadata?: string | null;
    payment_method: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    };
    processor?: string | null;
    reference?: string | null;
    sale_id?: string | null;
    status: AuthorizationStatus;
    timezone?: string | null;
    transaction_id?: string | null;
    updated_row_at?: string | null;
  };
};

export type CreateBackOfficeKeyMutationVariables = {
  merchant_uid: string;
  entity_level: BackofficeKeyEntityLevel;
  key_name?: string | null;
};

export type CreateBackOfficeKeyMutation = {
  createBackOfficeKey?: {
    __typename: 'BackofficeKeyDataWithKey';
    api_key?: string | null;
    created_date?: string | null;
    enabled?: boolean | null;
    entity_level?: BackofficeKeyEntityLevel | null;
    key_name?: string | null;
    last_accessed_date?: string | null;
    merchant_uid?: string | null;
  } | null;
};

export type CreateBarcodeMutationVariables = {
  input: BarcodeInput;
};

export type CreateBarcodeMutation = {
  // Create a barcode for use to make a cash payment
  createBarcode: {
    __typename: 'Barcode';
    account_code?: string | null;
    amount: number;
    barcode_id: string;
    barcode_url?: string | null;
    email_or_phone: string;
    expiration_date?: string | null;
    fees?: number | null;
    full_name: string;
    invoice_id?: string | null;
    merchant_uid: string;
    metadata?: string | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    payor_id?: string | null;
    reference?: string | null;
    sale_id?: string | null;
  };
};

export type CreateBarcodePaymentSandboxMutationVariables = {
  barcode_id: string;
  amount_to_pay: number;
};

export type CreateBarcodePaymentSandboxMutation = {
  // Create a simulated barcode payment in sandbox
  createBarcodePaymentSandbox: {
    __typename: 'BarcodePaymentResponse';
    confirmation_code: string;
    status: BarcodePaymentStatus;
    status_message: string;
  };
};

export type CreateCardPresentCompletionMutationVariables = {
  status: TransactionStatus;
  amount?: number | null;
  card_brand?: string | null;
  last_four?: string | null;
  service_fee?: number | null;
  transaction_id: string;
  created_at?: string | null;
  failure_reason?: Array<string | null> | null;
};

export type CreateCardPresentCompletionMutation = {
  createCardPresentCompletion?: {
    __typename: 'CardPresentPayment';
    amount?: number | null;
    card_brand?: string | null;
    created_at?: string | null;
    currency?: string | null;
    failure_reason?: Array<string | null> | null;
    last_four?: string | null;
    service_fee?: number | null;
    status?: TransactionStatus | null;
    transaction_id?: string | null;
  } | null;
};

export type CreateCaptureMutationVariables = {
  merchant_uid: string;
  authorization_id: string;
  amount: number;
  fee?: number | null;
  send_receipt?: boolean | null;
  receipt_description?: string | null;
  allow_reauth?: boolean | null;
  allow_exceeded_amount?: boolean | null;
  split?: Array<SplitInput | null> | null;
};

export type CreateCaptureMutation = {
  // Create a capture for the passed in authorization ID
  createCapture: {
    __typename: 'Transaction';
    account_code?: string | null;
    ach_return_details?: {
      __typename: 'AchReturnDetails';
      return_code?: string | null;
      return_details?: string | null;
      transfer_type?: AchReturnTransferType | null;
    } | null;
    additional_purchase_data?: {
      __typename: 'AdditionalPurchaseData';
      level3_data_line_item?: Array<{
        __typename: 'Level3DataLineItem';
        item_code?: string | null;
        item_description?: string | null;
        item_qty_exp?: number | null;
        prod_code?: string | null;
        qty?: number | null;
        tax_amount?: number | null;
        tax_ind?: TaxIndicatorType | null;
        tax_rate?: number | null;
        tax_rt_exp?: number | null;
        tax_type_id?: TaxType | null;
        unit_cost?: number | null;
        unit_of_msure?: string | null;
      } | null> | null;
      level3_data_summary?: {
        __typename: 'Level3DataSummary';
        dest_postal_code?: string | null;
        discnt_amt?: number | null;
        duty_amt?: number | null;
        frght_amt?: number | null;
        order_num?: string | null;
        prod_desc?: Array<string | null> | null;
        purch_idfr?: string | null;
        tax_amt?: number | null;
        tax_ind?: TaxIndicatorType | null;
      } | null;
    } | null;
    authorization_id?: string | null;
    avs_status?: string | null;
    currency?: string | null;
    device_id?: string | null;
    dispute_status?: DisputeStatus | null;
    failure_reasons?: Array<string | null> | null;
    fee_mode?: FeeMode | null;
    fees?: number | null;
    flag_for_review?: TransactionReviewStatus | null;
    gross_amount?: number | null;
    invoice?: {
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null;
    is_settled?: boolean | null;
    merchant?: {
      __typename: 'ListMerchant';
      ach_active?: boolean | null;
      card_active?: boolean | null;
      cash_active?: boolean | null;
      country_code?: string | null;
      is_system?: boolean | null;
      merchant_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      parent_merchant_uid?: string | null;
      submitted_onboarding?: boolean | null;
      updated_row_at?: string | null;
    } | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    net_amount?: number | null;
    parent_id?: string | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    processor?: string | null;
    recurring?: {
      __typename: 'RecurringPayment';
      account_code?: string | null;
      amount_per_payment?: number | null;
      created_date?: string | null;
      currency?: string | null;
      fee_mode?: FeeMode | null;
      fee_per_payment?: number | null;
      is_active?: boolean | null;
      is_processing?: boolean | null;
      recurring_id?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      mute_all_emails?: boolean | null;
      next_payment_date?: string | null;
      payment_interval?: RecurringInterval | null;
      payment_method?: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      } | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      prev_payment_date?: string | null;
      recurring_description?: string | null;
      recurring_name?: string | null;
      reference?: string | null;
      remaining_payments?: number | null;
      status?: RecurringStatus | null;
      total_amount_per_payment?: number | null;
    } | null;
    reference?: string | null;
    refund_reason?: {
      __typename: 'RefundReason';
      reason_code?: RefundReasonCode | null;
      reason_details?: string | null;
    } | null;
    refund_voidable?: boolean | null;
    refunded_amount?: number | null;
    sale_id?: string | null;
    settlement_batch?: number | null;
    splits?: Array<{
      __typename: 'Split';
      account_code?: string | null;
      amount: number;
      currency: string;
      created_at: string;
      id: string;
      merchant: {
        __typename: 'ListMerchant';
        ach_active?: boolean | null;
        card_active?: boolean | null;
        cash_active?: boolean | null;
        country_code?: string | null;
        is_system?: boolean | null;
        merchant_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        parent_merchant_uid?: string | null;
        submitted_onboarding?: boolean | null;
        updated_row_at?: string | null;
      };
      metadata: string;
      payment_method: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      };
      reference?: string | null;
      settlement_batch?: number | null;
      transaction_id: string;
      updated_row_at: string;
    } | null> | null;
    status?: TransactionStatus | null;
    timezone?: string | null;
    transaction_date?: string | null;
    transaction_id?: string | null;
    transaction_type?: TransactionType | null;
    updated_row_at?: string | null;
  };
};

export type CreateCardPresentPaymentMutationVariables = {
  merchant_uid: string;
  device_id: string;
  payor_id?: string | null;
  payor?: PayorInput | null;
  amount: number;
  recurring_id?: string | null;
  invoice_id?: string | null;
  fee?: number | null;
  fee_mode?: FeeMode | null;
  payment_parameters_name?: string | null;
  account_code?: string | null;
  reference?: string | null;
  send_receipt?: boolean | null;
  receipt_description?: string | null;
  timezone?: string | null;
  metadata?: string | null;
};

export type CreateCardPresentPaymentMutation = {
  createCardPresentPayment?: string | null;
};

export type CreateDeviceMutationVariables = {
  input: DeviceInput;
};

export type CreateDeviceMutation = {
  createDevice?: {
    __typename: 'Device';
    device_id?: string | null;
    device_description?: string | null;
    device_name?: string | null;
    device_properties?: string | null;
    is_active?: boolean | null;
    merchant_uid?: string | null;
    processor?: string | null;
    processor_device_id?: string | null;
  } | null;
};

export type CreateInvoiceMutationVariables = {
  input: InvoiceInput;
};

export type CreateInvoiceMutation = {
  createInvoice?: {
    __typename: 'Invoice';
    account_code?: string | null;
    created_date?: string | null;
    currency?: string | null;
    due_by?: string | null;
    fee_mode?: FeeMode | null;
    invoice_amount?: number | null;
    invoice_date?: string | null;
    invoice_description?: string | null;
    invoice_id?: string | null;
    invoice_name?: string | null;
    merchant_invoice_number?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    offline_transactions?: Array<{
      __typename: 'OfflineTransaction';
      amount?: number | null;
      instance_id?: string | null;
      invoice_id?: string | null;
      note?: string | null;
      transaction_date?: string | null;
      type?: OfflineTransactionType | null;
    } | null> | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    reference?: string | null;
    settings?: {
      __typename: 'InvoiceSettings';
      accepted_payment_methods?: {
        __typename: 'AcceptedPaymentMethods';
        ach?: boolean | null;
        card?: boolean | null;
        cash?: boolean | null;
      } | null;
      is_secure?: boolean | null;
      require_payor_address?: boolean | null;
      security_pin?: string | null;
    } | null;
    status?: InvoiceStatus | null;
    total_paid_amount?: number | null;
  } | null;
};

export type CreateInvoiceEmailMutationVariables = {
  invoice_id: string;
};

export type CreateInvoiceEmailMutation = {
  createInvoiceEmail?: boolean | null;
};

export type CreateMerchantMutationVariables = {
  merchant_name: string;
  parent_merchant_uid?: string | null;
  user?: MerchantUserInput | null;
  metadata?: string | null;
};

export type CreateMerchantMutation = {
  // Create a merchant by calling the pt-merchant service
  createMerchant?: {
    __typename: 'ListMerchant';
    ach_active?: boolean | null;
    card_active?: boolean | null;
    cash_active?: boolean | null;
    country_code?: string | null;
    is_system?: boolean | null;
    merchant_name?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    parent_merchant_uid?: string | null;
    submitted_onboarding?: boolean | null;
    updated_row_at?: string | null;
  } | null;
};

export type CreateMerchantOnboardingMutationVariables = {
  merchant_onboarding: MerchantOnboardingInput;
  support_note?: SupportNoteInput | null;
  is_form_completed: boolean;
};

export type CreateMerchantOnboardingMutation = {
  // Upsert the onboarding data with the option, to also include some initial supporting doc
  createMerchantOnboarding?: boolean | null;
};

export type CreateMerchantOnboardingDocumentSubmissionMutationVariables = {
  merchant_uid: string;
  ticket_id: string;
  attachment_ids: Array<string | null>;
  processor: string;
};

export type CreateMerchantOnboardingDocumentSubmissionMutation = {
  // Submit supporting docs to the processor (stored in fresh desk)
  createMerchantOnboardingDocumentSubmission?: boolean | null;
};

export type CreateMerchantOnboardingStatusChangeMutationVariables = {
  merchant_uid: string;
  want_to_unlock_data?: boolean | null;
  want_to_ask_for_docs?: boolean | null;
};

export type CreateMerchantOnboardingStatusChangeMutation = {
  // Change the statuses of key values for the onboarding data
  createMerchantOnboardingStatusChange?: boolean | null;
};

export type CreateMerchantOnboardingSubmissionMutationVariables = {
  merchant_uid: string;
  processor: string;
};

export type CreateMerchantOnboardingSubmissionMutation = {
  // Submit the onboarding data to the processor
  createMerchantOnboardingSubmission?: boolean | null;
};

export type CreateNativeAppMutationVariables = {
  input: NativeAppInput;
};

export type CreateNativeAppMutation = {
  createNativeApp?: boolean | null;
};

export type CreateNativeAppApplePayCSRMutationVariables = {
  merchant_uid: string;
  app_id: string;
};

export type CreateNativeAppApplePayCSRMutation = {
  createNativeAppApplePayCSR: string;
};

export type CreateNativeAppApplePayCertificateMutationVariables = {
  merchant_uid: string;
  app_id: string;
  certificate: string;
};

export type CreateNativeAppApplePayCertificateMutation = {
  createNativeAppApplePayCertificate: boolean;
};

export type CreateNotificationMutationVariables = {
  merchant_uid: string;
  context_id: NotificationContextId;
  primary_value?: string | null;
  secondary_value?: string | null;
  error?: string | null;
};

export type CreateNotificationMutation = {
  // Create an notification echo to trigger the subscription
  createNotification?: {
    __typename: 'Notification';
    context_id: NotificationContextId;
    error?: string | null;
    merchant_uid: string;
    primary_value?: string | null;
    secondary_value?: string | null;
  } | null;
};

export type CreateOfflineTransactionMutationVariables = {
  input: OfflineTransactionInput;
};

export type CreateOfflineTransactionMutation = {
  createOfflineTransaction?: {
    __typename: 'OfflineTransaction';
    amount?: number | null;
    instance_id?: string | null;
    invoice_id?: string | null;
    note?: string | null;
    transaction_date?: string | null;
    type?: OfflineTransactionType | null;
  } | null;
};

export type CreateOneTimePaymentMutationVariables = {
  merchant_uid: string;
  amount: number;
  payment_method_id?: string | null;
  payment_method?: PaymentMethodInput | null;
  recurring_id?: string | null;
  invoice_id?: string | null;
  fee?: number | null;
  fee_mode?: FeeMode | null;
  payment_parameters_name?: string | null;
  account_code?: string | null;
  reference?: string | null;
  send_receipt?: boolean | null;
  receipt_description?: string | null;
  metadata?: string | null;
  health_expense_type?: HealthExpenseType | null;
  additional_purchase_data?: AdditionalPurchaseDataInput | null;
};

export type CreateOneTimePaymentMutation = {
  // Deprecated, use createTransaction instead
  createOneTimePayment?: {
    __typename: 'OneTimePayment';
    amount?: number | null;
    card_brand?: string | null;
    created_at?: string | null;
    currency?: string | null;
    failure_reason?: string | null;
    last_four?: string | null;
    service_fee?: number | null;
    status?: TransactionStatus | null;
    transaction_id?: string | null;
  } | null;
};

export type CreatePaymentLinkMutationVariables = {
  input: PaymentLinkInput;
};

export type CreatePaymentLinkMutation = {
  createPaymentLink?: {
    __typename: 'PaymentLink';
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
    account_code?: string | null;
    amount?: number | null;
    amount_is_variable?: boolean | null;
    call_to_action?: CallToActionType | null;
    created_date?: string | null;
    currency?: string | null;
    custom_success_message?: string | null;
    fee_mode?: FeeMode | null;
    is_active?: boolean | null;
    link_id?: string | null;
    link_name?: string | null;
    link_url?: string | null;
    max_amount?: number | null;
    merchant_uid?: string | null;
    min_amount?: number | null;
    payment_name?: string | null;
    payment_description?: string | null;
    redirect_url?: string | null;
    require_phone?: boolean | null;
  } | null;
};

export type CreatePaymentMethodMutationVariables = {
  payment_method: PaymentMethodInput;
  merchant_uid: string;
  skip_validation?: boolean | null;
};

export type CreatePaymentMethodMutation = {
  createPaymentMethod?: {
    __typename: 'PaymentMethodToken';
    address_line1?: string | null;
    address_line2?: string | null;
    bank_account_type?: BankAccountType | null;
    bank_code?: string | null;
    barcode_id?: string | null;
    card_brand?: string | null;
    card_type?: CardType | null;
    city?: string | null;
    country?: string | null;
    exp_date?: string | null;
    full_name?: string | null;
    is_active?: boolean | null;
    issuing_country_code?: string | null;
    last_four?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    payment_method_id?: string | null;
    payment_type?: PaymentType | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    postal_code?: string | null;
    region?: string | null;
    wallet_type?: WalletType | null;
  } | null;
};

export type CreatePayorMutationVariables = {
  input: PayorInput;
};

export type CreatePayorMutation = {
  createPayor?: {
    __typename: 'Payor';
    address_line1?: string | null;
    address_line2?: string | null;
    city?: string | null;
    country?: string | null;
    email?: string | null;
    full_name?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    payor_id?: string | null;
    phone?: string | null;
    postal_code?: string | null;
    region?: string | null;
  } | null;
};

export type CreateReceiptEmailMutationVariables = {
  transaction_id: string;
  receipt_description?: string | null;
  email?: string | null;
};

export type CreateReceiptEmailMutation = {
  createReceiptEmail?: boolean | null;
};

export type CreateRecurringPaymentMutationVariables = {
  input: RecurringPaymentInput;
};

export type CreateRecurringPaymentMutation = {
  createRecurringPayment?: {
    __typename: 'RecurringPayment';
    account_code?: string | null;
    amount_per_payment?: number | null;
    created_date?: string | null;
    currency?: string | null;
    fee_mode?: FeeMode | null;
    fee_per_payment?: number | null;
    is_active?: boolean | null;
    is_processing?: boolean | null;
    recurring_id?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    mute_all_emails?: boolean | null;
    next_payment_date?: string | null;
    payment_interval?: RecurringInterval | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    prev_payment_date?: string | null;
    recurring_description?: string | null;
    recurring_name?: string | null;
    reference?: string | null;
    remaining_payments?: number | null;
    status?: RecurringStatus | null;
    total_amount_per_payment?: number | null;
  } | null;
};

export type CancelRecurringPaymentMutationVariables = {
  recurring_id: string;
};

export type CancelRecurringPaymentMutation = {
  cancelRecurringPayment?: boolean | null;
};

export type CreateRefundMutationVariables = {
  transaction_id: string;
  amount: number;
  refund_reason: RefundReasonInput;
  refund_email?: string | null;
};

export type CreateRefundMutation = {
  // Deprecated, use createReversal instead
  createRefund?: boolean | null;
};

export type CreateRetryForFailedRecurringPaymentMutationVariables = {
  recurring_id: string;
};

export type CreateRetryForFailedRecurringPaymentMutation = {
  createRetryForFailedRecurringPayment?: boolean | null;
};

export type CreateReversalMutationVariables = {
  transaction_id: string;
  amount: number;
  refund_reason: RefundReasonInput;
  refund_email?: string | null;
};

export type CreateReversalMutation = {
  // Reverse a transaction, which could be a void or a refund
  createReversal: {
    __typename: 'ReversalResponse';
    is_void: boolean;
    transaction_id?: string | null;
  };
};

export type CreateBatchCaptureMutationVariables = {
  merchant_uid: string;
};

export type CreateBatchCaptureMutation = {
  // Capture any pending transactions for a settlement batch in sandbox
  createBatchCapture?: boolean | null;
};

export type CreateSendDisputeEvidenceMutationVariables = {
  dispute_id: string;
  merchant_uid?: string | null;
};

export type CreateSendDisputeEvidenceMutation = {
  // Send unsent evidence records for a dispute to the processor
  createSendDisputeEvidence?: boolean | null;
};

export type CreateSuccessOnboardingEmailMutationVariables = {
  merchant_uid: string;
};

export type CreateSuccessOnboardingEmailMutation = {
  // Send the success email to the merchant
  createSuccessOnboardingEmail?: boolean | null;
};

export type CreateSupportNoteMutationVariables = {
  merchant_uid: string;
  ticket_id: string;
  support_note: SupportNoteInput;
};

export type CreateSupportNoteMutation = {
  // Upload supporting docs to fresh desk as a note with attachments
  createSupportNote?: boolean | null;
};

export type CreateTransactionMutationVariables = {
  merchant_uid: string;
  amount: number;
  payment_method_id?: string | null;
  payment_method?: PaymentMethodInput | null;
  recurring_id?: string | null;
  invoice_id?: string | null;
  fee?: number | null;
  fee_mode?: FeeMode | null;
  account_code?: string | null;
  reference?: string | null;
  send_receipt?: boolean | null;
  receipt_description?: string | null;
  metadata?: string | null;
  health_expense_type?: HealthExpenseType | null;
  additional_purchase_data?: AdditionalPurchaseDataInput | null;
  one_time_use_token?: boolean | null;
  split?: Array<SplitInput | null> | null;
};

export type CreateTransactionMutation = {
  // Create a payment transaction
  createTransaction: {
    __typename: 'Transaction';
    account_code?: string | null;
    ach_return_details?: {
      __typename: 'AchReturnDetails';
      return_code?: string | null;
      return_details?: string | null;
      transfer_type?: AchReturnTransferType | null;
    } | null;
    additional_purchase_data?: {
      __typename: 'AdditionalPurchaseData';
      level3_data_line_item?: Array<{
        __typename: 'Level3DataLineItem';
        item_code?: string | null;
        item_description?: string | null;
        item_qty_exp?: number | null;
        prod_code?: string | null;
        qty?: number | null;
        tax_amount?: number | null;
        tax_ind?: TaxIndicatorType | null;
        tax_rate?: number | null;
        tax_rt_exp?: number | null;
        tax_type_id?: TaxType | null;
        unit_cost?: number | null;
        unit_of_msure?: string | null;
      } | null> | null;
      level3_data_summary?: {
        __typename: 'Level3DataSummary';
        dest_postal_code?: string | null;
        discnt_amt?: number | null;
        duty_amt?: number | null;
        frght_amt?: number | null;
        order_num?: string | null;
        prod_desc?: Array<string | null> | null;
        purch_idfr?: string | null;
        tax_amt?: number | null;
        tax_ind?: TaxIndicatorType | null;
      } | null;
    } | null;
    authorization_id?: string | null;
    avs_status?: string | null;
    currency?: string | null;
    device_id?: string | null;
    dispute_status?: DisputeStatus | null;
    failure_reasons?: Array<string | null> | null;
    fee_mode?: FeeMode | null;
    fees?: number | null;
    flag_for_review?: TransactionReviewStatus | null;
    gross_amount?: number | null;
    invoice?: {
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null;
    is_settled?: boolean | null;
    merchant?: {
      __typename: 'ListMerchant';
      ach_active?: boolean | null;
      card_active?: boolean | null;
      cash_active?: boolean | null;
      country_code?: string | null;
      is_system?: boolean | null;
      merchant_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      parent_merchant_uid?: string | null;
      submitted_onboarding?: boolean | null;
      updated_row_at?: string | null;
    } | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    net_amount?: number | null;
    parent_id?: string | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    processor?: string | null;
    recurring?: {
      __typename: 'RecurringPayment';
      account_code?: string | null;
      amount_per_payment?: number | null;
      created_date?: string | null;
      currency?: string | null;
      fee_mode?: FeeMode | null;
      fee_per_payment?: number | null;
      is_active?: boolean | null;
      is_processing?: boolean | null;
      recurring_id?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      mute_all_emails?: boolean | null;
      next_payment_date?: string | null;
      payment_interval?: RecurringInterval | null;
      payment_method?: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      } | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      prev_payment_date?: string | null;
      recurring_description?: string | null;
      recurring_name?: string | null;
      reference?: string | null;
      remaining_payments?: number | null;
      status?: RecurringStatus | null;
      total_amount_per_payment?: number | null;
    } | null;
    reference?: string | null;
    refund_reason?: {
      __typename: 'RefundReason';
      reason_code?: RefundReasonCode | null;
      reason_details?: string | null;
    } | null;
    refund_voidable?: boolean | null;
    refunded_amount?: number | null;
    sale_id?: string | null;
    settlement_batch?: number | null;
    splits?: Array<{
      __typename: 'Split';
      account_code?: string | null;
      amount: number;
      currency: string;
      created_at: string;
      id: string;
      merchant: {
        __typename: 'ListMerchant';
        ach_active?: boolean | null;
        card_active?: boolean | null;
        cash_active?: boolean | null;
        country_code?: string | null;
        is_system?: boolean | null;
        merchant_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        parent_merchant_uid?: string | null;
        submitted_onboarding?: boolean | null;
        updated_row_at?: string | null;
      };
      metadata: string;
      payment_method: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      };
      reference?: string | null;
      settlement_batch?: number | null;
      transaction_id: string;
      updated_row_at: string;
    } | null> | null;
    status?: TransactionStatus | null;
    timezone?: string | null;
    transaction_date?: string | null;
    transaction_id?: string | null;
    transaction_type?: TransactionType | null;
    updated_row_at?: string | null;
  };
};

export type CreateWalletTransactionMutationVariables = {
  merchant_uid: string;
  digital_wallet_payload: string;
  payor_id?: string | null;
  payor?: PayorInput | null;
  recurring_id?: string | null;
  invoice_id?: string | null;
  account_code?: string | null;
  reference?: string | null;
  send_receipt?: boolean | null;
  receipt_description?: string | null;
  metadata?: string | null;
  health_expense_type?: HealthExpenseType | null;
};

export type CreateWalletTransactionMutation = {
  // Create a payment transaction for a digital wallet
  createWalletTransaction: {
    __typename: 'Transaction';
    account_code?: string | null;
    ach_return_details?: {
      __typename: 'AchReturnDetails';
      return_code?: string | null;
      return_details?: string | null;
      transfer_type?: AchReturnTransferType | null;
    } | null;
    additional_purchase_data?: {
      __typename: 'AdditionalPurchaseData';
      level3_data_line_item?: Array<{
        __typename: 'Level3DataLineItem';
        item_code?: string | null;
        item_description?: string | null;
        item_qty_exp?: number | null;
        prod_code?: string | null;
        qty?: number | null;
        tax_amount?: number | null;
        tax_ind?: TaxIndicatorType | null;
        tax_rate?: number | null;
        tax_rt_exp?: number | null;
        tax_type_id?: TaxType | null;
        unit_cost?: number | null;
        unit_of_msure?: string | null;
      } | null> | null;
      level3_data_summary?: {
        __typename: 'Level3DataSummary';
        dest_postal_code?: string | null;
        discnt_amt?: number | null;
        duty_amt?: number | null;
        frght_amt?: number | null;
        order_num?: string | null;
        prod_desc?: Array<string | null> | null;
        purch_idfr?: string | null;
        tax_amt?: number | null;
        tax_ind?: TaxIndicatorType | null;
      } | null;
    } | null;
    authorization_id?: string | null;
    avs_status?: string | null;
    currency?: string | null;
    device_id?: string | null;
    dispute_status?: DisputeStatus | null;
    failure_reasons?: Array<string | null> | null;
    fee_mode?: FeeMode | null;
    fees?: number | null;
    flag_for_review?: TransactionReviewStatus | null;
    gross_amount?: number | null;
    invoice?: {
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null;
    is_settled?: boolean | null;
    merchant?: {
      __typename: 'ListMerchant';
      ach_active?: boolean | null;
      card_active?: boolean | null;
      cash_active?: boolean | null;
      country_code?: string | null;
      is_system?: boolean | null;
      merchant_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      parent_merchant_uid?: string | null;
      submitted_onboarding?: boolean | null;
      updated_row_at?: string | null;
    } | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    net_amount?: number | null;
    parent_id?: string | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    processor?: string | null;
    recurring?: {
      __typename: 'RecurringPayment';
      account_code?: string | null;
      amount_per_payment?: number | null;
      created_date?: string | null;
      currency?: string | null;
      fee_mode?: FeeMode | null;
      fee_per_payment?: number | null;
      is_active?: boolean | null;
      is_processing?: boolean | null;
      recurring_id?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      mute_all_emails?: boolean | null;
      next_payment_date?: string | null;
      payment_interval?: RecurringInterval | null;
      payment_method?: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      } | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      prev_payment_date?: string | null;
      recurring_description?: string | null;
      recurring_name?: string | null;
      reference?: string | null;
      remaining_payments?: number | null;
      status?: RecurringStatus | null;
      total_amount_per_payment?: number | null;
    } | null;
    reference?: string | null;
    refund_reason?: {
      __typename: 'RefundReason';
      reason_code?: RefundReasonCode | null;
      reason_details?: string | null;
    } | null;
    refund_voidable?: boolean | null;
    refunded_amount?: number | null;
    sale_id?: string | null;
    settlement_batch?: number | null;
    splits?: Array<{
      __typename: 'Split';
      account_code?: string | null;
      amount: number;
      currency: string;
      created_at: string;
      id: string;
      merchant: {
        __typename: 'ListMerchant';
        ach_active?: boolean | null;
        card_active?: boolean | null;
        cash_active?: boolean | null;
        country_code?: string | null;
        is_system?: boolean | null;
        merchant_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        parent_merchant_uid?: string | null;
        submitted_onboarding?: boolean | null;
        updated_row_at?: string | null;
      };
      metadata: string;
      payment_method: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      };
      reference?: string | null;
      settlement_batch?: number | null;
      transaction_id: string;
      updated_row_at: string;
    } | null> | null;
    status?: TransactionStatus | null;
    timezone?: string | null;
    transaction_date?: string | null;
    transaction_id?: string | null;
    transaction_type?: TransactionType | null;
    updated_row_at?: string | null;
  };
};

export type CreateUserMutationVariables = {
  input: UserInput;
};

export type CreateUserMutation = {
  // Allows us to create a user while bypassing the merchant validation
  createUser?: {
    __typename: 'User';
    email?: string | null;
    full_name?: string | null;
    phone?: string | null;
    user_status?: string | null;
    username?: string | null;
  } | null;
};

export type CreateUserV2MutationVariables = {
  input: UserInputV2;
};

export type CreateUserV2Mutation = {
  createUserV2?: boolean | null;
};

export type CreateUserPasswordResetMutationVariables = {
  username: string;
  user_pool: UserPool;
};

export type CreateUserPasswordResetMutation = {
  createUserPasswordReset?: boolean | null;
};

export type CreateVoidForAuthorizationMutationVariables = {
  authorization_id: string;
  void_amount?: number | null;
};

export type CreateVoidForAuthorizationMutation = {
  createVoidForAuthorization?: boolean | null;
};

export type CreateVoidForRefundMutationVariables = {
  transaction_id: string;
};

export type CreateVoidForRefundMutation = {
  createVoidForRefund?: boolean | null;
};

export type CreateWebhookMutationVariables = {
  endpoint: string;
  name: string;
};

export type CreateWebhookMutation = {
  createWebhook: boolean;
};

export type UpdateTransactionInReviewMutationVariables = {
  transaction_id: string;
  action: TransactionReviewAction;
};

export type UpdateTransactionInReviewMutation = {
  // Approve or decline a transaction that is flagged for review
  updateTransactionInReview?: boolean | null;
};

export type DeleteBackOfficeKeyMutationVariables = {
  merchant_uid: string;
  key_name: string;
};

export type DeleteBackOfficeKeyMutation = {
  deleteBackOfficeKey?: boolean | null;
};

export type DeleteDeviceMutationVariables = {
  merchant_uid: string;
  device_id: string;
};

export type DeleteDeviceMutation = {
  deleteDevice?: boolean | null;
};

export type DeleteDisputeEvidenceMutationVariables = {
  dispute_id: string;
  filename: string;
  merchant_uid: string;
};

export type DeleteDisputeEvidenceMutation = {
  // Delete an evidence record for a dispute
  deleteDisputeEvidence?: boolean | null;
};

export type DeleteInvoiceMutationVariables = {
  invoice_id: string;
};

export type DeleteInvoiceMutation = {
  deleteInvoice?: boolean | null;
};

export type DeleteMetadataMutationVariables = {
  id: string;
  metadata_associate: MetadataAssociate;
  merchant_uid: string;
  metadata_keys: Array<string | null>;
};

export type DeleteMetadataMutation = {
  deleteMetadata?: boolean | null;
};

export type DeleteNativeAppMutationVariables = {
  merchant_uid: string;
  app_id: string;
};

export type DeleteNativeAppMutation = {
  deleteNativeApp?: boolean | null;
};

export type DeleteUserMutationVariables = {
  username: string;
  user_pool: UserPool;
};

export type DeleteUserMutation = {
  deleteUser?: boolean | null;
};

export type DeleteUserV2MutationVariables = {
  username: string;
  user_pool: UserPool;
};

export type DeleteUserV2Mutation = {
  deleteUserV2?: boolean | null;
};

export type DeleteWebhookMutationVariables = {
  endpoint: string;
};

export type DeleteWebhookMutation = {
  deleteWebhook: boolean;
};

export type UpdateBackOfficeKeyMutationVariables = {
  merchant_uid: string;
  key_name: string;
  enabled?: boolean | null;
};

export type UpdateBackOfficeKeyMutation = {
  updateBackOfficeKey?: boolean | null;
};

export type UpdateDeviceMutationVariables = {
  merchant_uid: string;
  device_id: string;
  device_description: string;
};

export type UpdateDeviceMutation = {
  updateDevice?: boolean | null;
};

export type UpdateFeeMatrixMutationVariables = {
  fee_matrix: FeeMatrixInput;
};

export type UpdateFeeMatrixMutation = {
  updateFeeMatrix?: {
    __typename: 'FeeMatrix';
    ach: {
      __typename: 'AchFee';
      merchant_fee?: {
        __typename: 'AchMerchantFee';
        basis_points: number;
        fixed: number;
        max_fee?: number | null;
      } | null;
      service_fee?: {
        __typename: 'AchServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        max_fee?: number | null;
        min_fee?: number | null;
      } | null;
    };
    ach_return_fee: number;
    ach_return_disputed_fee: number;
    business_credit?: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    } | null;
    business_debit?: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    } | null;
    amex?: {
      __typename: 'CardBrandFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
    } | null;
    card: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    };
    card_account_updater: number;
    cash: number;
    chargeback_fee: number;
    credit_card?: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    } | null;
    debit_card?: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    } | null;
    discover?: {
      __typename: 'CardBrandFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
    } | null;
    interchange_plus: boolean;
    international_card_basis: number;
    mastercard?: {
      __typename: 'CardBrandFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
    } | null;
    merchant_uid: string;
    prepaid_card?: {
      __typename: 'CardFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'CardServiceFee';
        basis_points?: number | null;
        fixed?: number | null;
        min_fee?: number | null;
      } | null;
    } | null;
    service_fee_enabled: boolean;
    visa?: {
      __typename: 'CardBrandFee';
      merchant_fee?: {
        __typename: 'CardMerchantFee';
        basis_points?: number | null;
        fixed?: number | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateInvoiceMutationVariables = {
  invoice_id: string;
  invoice_update_input: InvoiceUpdateInput;
};

export type UpdateInvoiceMutation = {
  // Update any invoice fields except the payor
  updateInvoice?: boolean | null;
};

export type UpdateMerchantSettingsMutationVariables = {
  merchant_uid: string;
  settings: MerchantSettingsInput;
};

export type UpdateMerchantSettingsMutation = {
  // Update the settings for a pt-merchant service record
  updateMerchantSettings?: boolean | null;
};

export type UpdateMetadataMutationVariables = {
  id: string;
  metadata_associate: MetadataAssociate;
  merchant_uid: string;
  metadata: string;
};

export type UpdateMetadataMutation = {
  updateMetadata?: boolean | null;
};

export type UpdateNativeAppMutationVariables = {
  input: NativeAppInput;
};

export type UpdateNativeAppMutation = {
  updateNativeApp?: boolean | null;
};

export type UpdatePaymentLinkMutationVariables = {
  input: UpdatePaymentLinkInput;
};

export type UpdatePaymentLinkMutation = {
  updatePaymentLink?: {
    __typename: 'PaymentLink';
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
    account_code?: string | null;
    amount?: number | null;
    amount_is_variable?: boolean | null;
    call_to_action?: CallToActionType | null;
    created_date?: string | null;
    currency?: string | null;
    custom_success_message?: string | null;
    fee_mode?: FeeMode | null;
    is_active?: boolean | null;
    link_id?: string | null;
    link_name?: string | null;
    link_url?: string | null;
    max_amount?: number | null;
    merchant_uid?: string | null;
    min_amount?: number | null;
    payment_name?: string | null;
    payment_description?: string | null;
    redirect_url?: string | null;
    require_phone?: boolean | null;
  } | null;
};

export type UpdatePaymentMethodToDisabledMutationVariables = {
  merchant_uid: string;
  payment_method_id: string;
};

export type UpdatePaymentMethodToDisabledMutation = {
  updatePaymentMethodToDisabled: boolean;
};

export type UpdatePayorMutationVariables = {
  payor_id: string;
  payor_data: PayorData;
};

export type UpdatePayorMutation = {
  updatePayor?: boolean | null;
};

export type UpdateRecurringPaymentMutationVariables = {
  input: UpdateRecurringPaymentInput;
};

export type UpdateRecurringPaymentMutation = {
  updateRecurringPayment?: {
    __typename: 'RecurringPayment';
    account_code?: string | null;
    amount_per_payment?: number | null;
    created_date?: string | null;
    currency?: string | null;
    fee_mode?: FeeMode | null;
    fee_per_payment?: number | null;
    is_active?: boolean | null;
    is_processing?: boolean | null;
    recurring_id?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    mute_all_emails?: boolean | null;
    next_payment_date?: string | null;
    payment_interval?: RecurringInterval | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    prev_payment_date?: string | null;
    recurring_description?: string | null;
    recurring_name?: string | null;
    reference?: string | null;
    remaining_payments?: number | null;
    status?: RecurringStatus | null;
    total_amount_per_payment?: number | null;
  } | null;
};

export type UpdateRecurringPaymentMethodMutationVariables = {
  recurring_hash: string;
  payment_method_id: string;
};

export type UpdateRecurringPaymentMethodMutation = {
  updateRecurringPaymentMethod?: {
    __typename: 'RecurringPayment';
    account_code?: string | null;
    amount_per_payment?: number | null;
    created_date?: string | null;
    currency?: string | null;
    fee_mode?: FeeMode | null;
    fee_per_payment?: number | null;
    is_active?: boolean | null;
    is_processing?: boolean | null;
    recurring_id?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    mute_all_emails?: boolean | null;
    next_payment_date?: string | null;
    payment_interval?: RecurringInterval | null;
    payment_method?: {
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    prev_payment_date?: string | null;
    recurring_description?: string | null;
    recurring_name?: string | null;
    reference?: string | null;
    remaining_payments?: number | null;
    status?: RecurringStatus | null;
    total_amount_per_payment?: number | null;
  } | null;
};

export type UpdateWalletRegistrationAppleMutationVariables = {
  input: WalletAppleInput;
};

export type UpdateWalletRegistrationAppleMutation = {
  updateWalletRegistrationApple: {
    __typename: 'WalletRegistrationAppleResult';
    successful_domain_names: Array<string | null>;
    failed_domain_names: Array<string | null>;
  };
};

export type UpdateWebhookMutationVariables = {
  endpoint: string;
  name?: string | null;
  is_active?: boolean | null;
};

export type UpdateWebhookMutation = {
  updateWebhook: boolean;
};

export type UpdateTransactionAccountCodeMutationVariables = {
  transaction_id: string;
  account_code: string;
};

export type UpdateTransactionAccountCodeMutation = {
  // Woo Commerce will call this endpoint to update the account code for the transaction with the Woo order identifier
  updateTransactionAccountCode?: boolean | null;
};

export type AppleDomainVerificationFileQueryVariables = {};

export type AppleDomainVerificationFileQuery = {
  appleDomainVerificationFile: string;
};

export type AuthorizationsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type AuthorizationsQuery = {
  authorizations?: {
    __typename: 'Authorizations';
    items?: Array<{
      __typename: 'Authorization';
      account_code?: string | null;
      additional_purchase_data?: {
        __typename: 'AdditionalPurchaseData';
        level3_data_line_item?: Array<{
          __typename: 'Level3DataLineItem';
          item_code?: string | null;
          item_description?: string | null;
          item_qty_exp?: number | null;
          prod_code?: string | null;
          qty?: number | null;
          tax_amount?: number | null;
          tax_ind?: TaxIndicatorType | null;
          tax_rate?: number | null;
          tax_rt_exp?: number | null;
          tax_type_id?: TaxType | null;
          unit_cost?: number | null;
          unit_of_msure?: string | null;
        } | null> | null;
        level3_data_summary?: {
          __typename: 'Level3DataSummary';
          dest_postal_code?: string | null;
          discnt_amt?: number | null;
          duty_amt?: number | null;
          frght_amt?: number | null;
          order_num?: string | null;
          prod_desc?: Array<string | null> | null;
          purch_idfr?: string | null;
          tax_amt?: number | null;
          tax_ind?: TaxIndicatorType | null;
        } | null;
      } | null;
      amount: number;
      authorization_date: string;
      authorization_id: string;
      avs_status?: string | null;
      captured_amount?: number | null;
      currency: string;
      device_id?: string | null;
      expiration_date?: string | null;
      failure_reasons?: Array<string | null> | null;
      fee_mode: FeeMode;
      fees: number;
      invoice?: {
        __typename: 'Invoice';
        account_code?: string | null;
        created_date?: string | null;
        currency?: string | null;
        due_by?: string | null;
        fee_mode?: FeeMode | null;
        invoice_amount?: number | null;
        invoice_date?: string | null;
        invoice_description?: string | null;
        invoice_id?: string | null;
        invoice_name?: string | null;
        merchant_invoice_number?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        offline_transactions?: Array<{
          __typename: 'OfflineTransaction';
          amount?: number | null;
          instance_id?: string | null;
          invoice_id?: string | null;
          note?: string | null;
          transaction_date?: string | null;
          type?: OfflineTransactionType | null;
        } | null> | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        reference?: string | null;
        settings?: {
          __typename: 'InvoiceSettings';
          accepted_payment_methods?: {
            __typename: 'AcceptedPaymentMethods';
            ach?: boolean | null;
            card?: boolean | null;
            cash?: boolean | null;
          } | null;
          is_secure?: boolean | null;
          require_payor_address?: boolean | null;
          security_pin?: string | null;
        } | null;
        status?: InvoiceStatus | null;
        total_paid_amount?: number | null;
      } | null;
      merchant_uid: string;
      metadata?: string | null;
      payment_method: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      };
      processor?: string | null;
      reference?: string | null;
      sale_id?: string | null;
      status: AuthorizationStatus;
      timezone?: string | null;
      transaction_id?: string | null;
      updated_row_at?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type BackOfficeKeyQueryVariables = {
  merchant_uid: string;
  entity_level: BackofficeKeyEntityLevel;
};

export type BackOfficeKeyQuery = {
  backOfficeKey?: Array<{
    __typename: 'BackofficeKeyData';
    created_date?: string | null;
    enabled?: boolean | null;
    entity_level?: BackofficeKeyEntityLevel | null;
    key_name?: string | null;
    last_accessed_date?: string | null;
    merchant_uid?: string | null;
  } | null> | null;
};

export type BarcodeQueryVariables = {
  merchant_uid: string;
  barcode_id: string;
};

export type BarcodeQuery = {
  // Get back a barcode record using the barcode UID
  barcode?: {
    __typename: 'Barcode';
    account_code?: string | null;
    amount: number;
    barcode_id: string;
    barcode_url?: string | null;
    email_or_phone: string;
    expiration_date?: string | null;
    fees?: number | null;
    full_name: string;
    invoice_id?: string | null;
    merchant_uid: string;
    metadata?: string | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    payor_id?: string | null;
    reference?: string | null;
    sale_id?: string | null;
  } | null;
};

export type DashboardPaymentsQueryVariables = {
  merchant_uid: string;
  start_date: string;
  end_date: string;
};

export type DashboardPaymentsQuery = {
  dashboardPayments?: {
    __typename: 'DashboardPayments';
    gross_amount?: Array<string | null> | null;
  } | null;
};

export type DashboardPaymentMethodQueryVariables = {
  merchant_uid: string;
  start_date: string;
  end_date: string;
};

export type DashboardPaymentMethodQuery = {
  dashboardPaymentMethod?: {
    __typename: 'DashboardPaymentMethod';
    card?: string | null;
    ach?: string | null;
    cash?: string | null;
  } | null;
};

export type DashboardFundingTotalsQueryVariables = {
  merchant_uid: string;
  start_date: string;
  end_date: string;
};

export type DashboardFundingTotalsQuery = {
  dashboardFundingTotals?: {
    __typename: 'DashboardFundingTotals';
    payments?: string | null;
    refunds?: string | null;
    fees?: string | null;
    ach_returns?: string | null;
    disputes?: string | null;
    adjustments?: string | null;
  } | null;
};

export type DashboardAuthRateQueryVariables = {
  merchant_uid: string;
  start_date: string;
  end_date: string;
};

export type DashboardAuthRateQuery = {
  dashboardAuthRate?: {
    __typename: 'DashboardAuthRate';
    card?: Array<number | null> | null;
    ach?: Array<number | null> | null;
  } | null;
};

export type DashboardDisputeRateQueryVariables = {
  merchant_uid: string;
  start_date: string;
  end_date: string;
};

export type DashboardDisputeRateQuery = {
  dashboardDisputeRate?: {
    __typename: 'DashboardDisputeRate';
    card?: Array<number | null> | null;
    ach?: Array<number | null> | null;
  } | null;
};

export type DefaultPaymentParametersQueryVariables = {};

export type DefaultPaymentParametersQuery = {
  // Get the default payment parameter row
  defaultPaymentParameters?: {
    __typename: 'PaymentParameters';
    denied_after_date?: string | null;
    denied_before_date?: string | null;
    enabled: boolean;
    expires_in?: number | null;
    maximum_amount?: number | null;
    maximum_occurrence?: number | null;
    minimum_amount?: number | null;
    payment_parameters_name?: string | null;
    payment_parameters_type: string;
    recurrence_period?: string | null;
    validation_link_url?: string | null;
  } | null;
};

export type DevicesQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type DevicesQuery = {
  devices?: {
    __typename: 'Devices';
    items?: Array<{
      __typename: 'Device';
      device_id?: string | null;
      device_description?: string | null;
      device_name?: string | null;
      device_properties?: string | null;
      is_active?: boolean | null;
      merchant_uid?: string | null;
      processor?: string | null;
      processor_device_id?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type DisputeEvidenceQueryVariables = {
  dispute_id: string;
  merchant_uid?: string | null;
};

export type DisputeEvidenceQuery = {
  // Get dispute evidence records for a dispute
  disputeEvidence: Array<{
    __typename: 'DisputeEvidence';
    dispute_id?: string | null;
    evidence_date?: string | null;
    filename?: string | null;
    merchant_uid?: string | null;
    status?: EvidenceStatus | null;
    updated_date?: string | null;
  } | null>;
};

export type DisputeEvidenceTokensQueryVariables = {
  action: AwsS3Action;
  dispute_id: string;
  filenames: Array<string | null>;
  mime_types?: Array<string> | null;
  merchant_uid?: string | null;
};

export type DisputeEvidenceTokensQuery = {
  // Get AWS S3 access tokens for dispute evidence files
  // The mime types (will be used as is)
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  disputeEvidenceTokens: Array<string | null>;
};

export type DisputesQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type DisputesQuery = {
  disputes?: {
    __typename: 'Disputes';
    items?: Array<{
      __typename: 'Dispute';
      amount?: number | null;
      dispute_date?: string | null;
      dispute_id?: string | null;
      evidence_last_send_date?: string | null;
      expiration_date?: string | null;
      merchant_uid?: string | null;
      status?: DisputeStatus | null;
      reason?: DisputeReason | null;
      reason_message?: string | null;
      settlement_deposit_batch?: string | null;
      settlement_withdrawal_batch?: string | null;
      transaction?: {
        __typename: 'Transaction';
        account_code?: string | null;
        ach_return_details?: {
          __typename: 'AchReturnDetails';
          return_code?: string | null;
          return_details?: string | null;
          transfer_type?: AchReturnTransferType | null;
        } | null;
        additional_purchase_data?: {
          __typename: 'AdditionalPurchaseData';
          level3_data_line_item?: Array<{
            __typename: 'Level3DataLineItem';
            item_code?: string | null;
            item_description?: string | null;
            item_qty_exp?: number | null;
            prod_code?: string | null;
            qty?: number | null;
            tax_amount?: number | null;
            tax_ind?: TaxIndicatorType | null;
            tax_rate?: number | null;
            tax_rt_exp?: number | null;
            tax_type_id?: TaxType | null;
            unit_cost?: number | null;
            unit_of_msure?: string | null;
          } | null> | null;
          level3_data_summary?: {
            __typename: 'Level3DataSummary';
            dest_postal_code?: string | null;
            discnt_amt?: number | null;
            duty_amt?: number | null;
            frght_amt?: number | null;
            order_num?: string | null;
            prod_desc?: Array<string | null> | null;
            purch_idfr?: string | null;
            tax_amt?: number | null;
            tax_ind?: TaxIndicatorType | null;
          } | null;
        } | null;
        authorization_id?: string | null;
        avs_status?: string | null;
        currency?: string | null;
        device_id?: string | null;
        dispute_status?: DisputeStatus | null;
        failure_reasons?: Array<string | null> | null;
        fee_mode?: FeeMode | null;
        fees?: number | null;
        flag_for_review?: TransactionReviewStatus | null;
        gross_amount?: number | null;
        invoice?: {
          __typename: 'Invoice';
          account_code?: string | null;
          created_date?: string | null;
          currency?: string | null;
          due_by?: string | null;
          fee_mode?: FeeMode | null;
          invoice_amount?: number | null;
          invoice_date?: string | null;
          invoice_description?: string | null;
          invoice_id?: string | null;
          invoice_name?: string | null;
          merchant_invoice_number?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          offline_transactions?: Array<{
            __typename: 'OfflineTransaction';
            amount?: number | null;
            instance_id?: string | null;
            invoice_id?: string | null;
            note?: string | null;
            transaction_date?: string | null;
            type?: OfflineTransactionType | null;
          } | null> | null;
          payor?: {
            __typename: 'Payor';
            address_line1?: string | null;
            address_line2?: string | null;
            city?: string | null;
            country?: string | null;
            email?: string | null;
            full_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payor_id?: string | null;
            phone?: string | null;
            postal_code?: string | null;
            region?: string | null;
          } | null;
          reference?: string | null;
          settings?: {
            __typename: 'InvoiceSettings';
            is_secure?: boolean | null;
            require_payor_address?: boolean | null;
            security_pin?: string | null;
          } | null;
          status?: InvoiceStatus | null;
          total_paid_amount?: number | null;
        } | null;
        is_settled?: boolean | null;
        merchant?: {
          __typename: 'ListMerchant';
          ach_active?: boolean | null;
          card_active?: boolean | null;
          cash_active?: boolean | null;
          country_code?: string | null;
          is_system?: boolean | null;
          merchant_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          parent_merchant_uid?: string | null;
          submitted_onboarding?: boolean | null;
          updated_row_at?: string | null;
        } | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        net_amount?: number | null;
        parent_id?: string | null;
        payment_method?: {
          __typename: 'PaymentMethodToken';
          address_line1?: string | null;
          address_line2?: string | null;
          bank_account_type?: BankAccountType | null;
          bank_code?: string | null;
          barcode_id?: string | null;
          card_brand?: string | null;
          card_type?: CardType | null;
          city?: string | null;
          country?: string | null;
          exp_date?: string | null;
          full_name?: string | null;
          is_active?: boolean | null;
          issuing_country_code?: string | null;
          last_four?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payment_method_id?: string | null;
          payment_type?: PaymentType | null;
          payor?: {
            __typename: 'Payor';
            address_line1?: string | null;
            address_line2?: string | null;
            city?: string | null;
            country?: string | null;
            email?: string | null;
            full_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payor_id?: string | null;
            phone?: string | null;
            postal_code?: string | null;
            region?: string | null;
          } | null;
          postal_code?: string | null;
          region?: string | null;
          wallet_type?: WalletType | null;
        } | null;
        processor?: string | null;
        recurring?: {
          __typename: 'RecurringPayment';
          account_code?: string | null;
          amount_per_payment?: number | null;
          created_date?: string | null;
          currency?: string | null;
          fee_mode?: FeeMode | null;
          fee_per_payment?: number | null;
          is_active?: boolean | null;
          is_processing?: boolean | null;
          recurring_id?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          mute_all_emails?: boolean | null;
          next_payment_date?: string | null;
          payment_interval?: RecurringInterval | null;
          payment_method?: {
            __typename: 'PaymentMethodToken';
            address_line1?: string | null;
            address_line2?: string | null;
            bank_account_type?: BankAccountType | null;
            bank_code?: string | null;
            barcode_id?: string | null;
            card_brand?: string | null;
            card_type?: CardType | null;
            city?: string | null;
            country?: string | null;
            exp_date?: string | null;
            full_name?: string | null;
            is_active?: boolean | null;
            issuing_country_code?: string | null;
            last_four?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payment_method_id?: string | null;
            payment_type?: PaymentType | null;
            postal_code?: string | null;
            region?: string | null;
            wallet_type?: WalletType | null;
          } | null;
          payor?: {
            __typename: 'Payor';
            address_line1?: string | null;
            address_line2?: string | null;
            city?: string | null;
            country?: string | null;
            email?: string | null;
            full_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payor_id?: string | null;
            phone?: string | null;
            postal_code?: string | null;
            region?: string | null;
          } | null;
          prev_payment_date?: string | null;
          recurring_description?: string | null;
          recurring_name?: string | null;
          reference?: string | null;
          remaining_payments?: number | null;
          status?: RecurringStatus | null;
          total_amount_per_payment?: number | null;
        } | null;
        reference?: string | null;
        refund_reason?: {
          __typename: 'RefundReason';
          reason_code?: RefundReasonCode | null;
          reason_details?: string | null;
        } | null;
        refund_voidable?: boolean | null;
        refunded_amount?: number | null;
        sale_id?: string | null;
        settlement_batch?: number | null;
        splits?: Array<{
          __typename: 'Split';
          account_code?: string | null;
          amount: number;
          currency: string;
          created_at: string;
          id: string;
          merchant: {
            __typename: 'ListMerchant';
            ach_active?: boolean | null;
            card_active?: boolean | null;
            cash_active?: boolean | null;
            country_code?: string | null;
            is_system?: boolean | null;
            merchant_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            parent_merchant_uid?: string | null;
            submitted_onboarding?: boolean | null;
            updated_row_at?: string | null;
          };
          metadata: string;
          payment_method: {
            __typename: 'PaymentMethodToken';
            address_line1?: string | null;
            address_line2?: string | null;
            bank_account_type?: BankAccountType | null;
            bank_code?: string | null;
            barcode_id?: string | null;
            card_brand?: string | null;
            card_type?: CardType | null;
            city?: string | null;
            country?: string | null;
            exp_date?: string | null;
            full_name?: string | null;
            is_active?: boolean | null;
            issuing_country_code?: string | null;
            last_four?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payment_method_id?: string | null;
            payment_type?: PaymentType | null;
            postal_code?: string | null;
            region?: string | null;
            wallet_type?: WalletType | null;
          };
          reference?: string | null;
          settlement_batch?: number | null;
          transaction_id: string;
          updated_row_at: string;
        } | null> | null;
        status?: TransactionStatus | null;
        timezone?: string | null;
        transaction_date?: string | null;
        transaction_id?: string | null;
        transaction_type?: TransactionType | null;
        updated_row_at?: string | null;
      } | null;
      updated_date?: string | null;
      updated_row_at?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type ErrorTextQueryVariables = {
  language_code: string;
  error_codes: Array<string | null>;
};

export type ErrorTextQuery = {
  // Convert the error code into a language specific error text
  errorText: Array<string | null>;
};

export type GenerateCSVQueryVariables = {
  csvInput: CSVInput;
};

export type GenerateCSVQuery = {
  generateCSV?: {
    __typename: 'CSVOutput';
    bucket_name?: string | null;
    filepaths?: Array<string | null> | null;
  } | null;
};

export type HostedCheckoutPageQueryVariables = {
  session_id: string;
};

export type HostedCheckoutPageQuery = {
  hostedCheckoutPage?: {
    __typename: 'HostedCheckout';
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
    account_code?: string | null;
    amount?: number | null;
    api_key?: string | null;
    call_to_action?: CallToActionType | null;
    checkout_type?: string | null;
    country_code?: string | null;
    currency?: string | null;
    fee_mode?: FeeMode | null;
    invoice_id?: string | null;
    is_active?: boolean | null;
    merchant_name?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    origin?: string | null;
    payment_description?: string | null;
    payment_name?: string | null;
    payment_parameters?: string | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    recurring_id?: string | null;
    require_phone?: boolean | null;
  } | null;
};

export type InvoiceCheckoutPageQueryVariables = {
  invoice_id: string;
};

export type InvoiceCheckoutPageQuery = {
  invoiceCheckoutPage?: {
    __typename: 'InvoiceForCheckout';
    account_code?: string | null;
    api_key?: string | null;
    country_code?: string | null;
    currency?: string | null;
    fee_mode?: FeeMode | null;
    invoice_amount?: number | null;
    invoice_description?: string | null;
    invoice_id?: string | null;
    invoice_name?: string | null;
    merchant_name?: string | null;
    merchant_uid?: string | null;
    payor?: {
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null;
    reference?: string | null;
    settings?: {
      __typename: 'InvoiceSettings';
      accepted_payment_methods?: {
        __typename: 'AcceptedPaymentMethods';
        ach?: boolean | null;
        card?: boolean | null;
        cash?: boolean | null;
      } | null;
      is_secure?: boolean | null;
      require_payor_address?: boolean | null;
      security_pin?: string | null;
    } | null;
    status?: InvoiceStatus | null;
    total_paid_amount?: number | null;
  } | null;
};

export type InvoicesQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type InvoicesQuery = {
  invoices?: {
    __typename: 'Invoices';
    items?: Array<{
      __typename: 'Invoice';
      account_code?: string | null;
      created_date?: string | null;
      currency?: string | null;
      due_by?: string | null;
      fee_mode?: FeeMode | null;
      invoice_amount?: number | null;
      invoice_date?: string | null;
      invoice_description?: string | null;
      invoice_id?: string | null;
      invoice_name?: string | null;
      merchant_invoice_number?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      offline_transactions?: Array<{
        __typename: 'OfflineTransaction';
        amount?: number | null;
        instance_id?: string | null;
        invoice_id?: string | null;
        note?: string | null;
        transaction_date?: string | null;
        type?: OfflineTransactionType | null;
      } | null> | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      reference?: string | null;
      settings?: {
        __typename: 'InvoiceSettings';
        accepted_payment_methods?: {
          __typename: 'AcceptedPaymentMethods';
          ach?: boolean | null;
          card?: boolean | null;
          cash?: boolean | null;
        } | null;
        is_secure?: boolean | null;
        require_payor_address?: boolean | null;
        security_pin?: string | null;
      } | null;
      status?: InvoiceStatus | null;
      total_paid_amount?: number | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type MerchantQueryVariables = {
  merchant_uid?: string | null;
  merchant_name?: string | null;
};

export type MerchantQuery = {
  merchant?: {
    __typename: 'Merchant';
    ach_active?: boolean | null;
    api_key?: string | null;
    card_active?: boolean | null;
    cash_active?: boolean | null;
    country_code?: string | null;
    fee_matrix?: {
      __typename: 'FeeMatrix';
      ach: {
        __typename: 'AchFee';
        merchant_fee?: {
          __typename: 'AchMerchantFee';
          basis_points: number;
          fixed: number;
          max_fee?: number | null;
        } | null;
        service_fee?: {
          __typename: 'AchServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          max_fee?: number | null;
          min_fee?: number | null;
        } | null;
      };
      ach_return_fee: number;
      ach_return_disputed_fee: number;
      business_credit?: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      } | null;
      business_debit?: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      } | null;
      amex?: {
        __typename: 'CardBrandFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
      } | null;
      card: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      };
      card_account_updater: number;
      cash: number;
      chargeback_fee: number;
      credit_card?: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      } | null;
      debit_card?: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      } | null;
      discover?: {
        __typename: 'CardBrandFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
      } | null;
      interchange_plus: boolean;
      international_card_basis: number;
      mastercard?: {
        __typename: 'CardBrandFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
      } | null;
      merchant_uid: string;
      prepaid_card?: {
        __typename: 'CardFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
        service_fee?: {
          __typename: 'CardServiceFee';
          basis_points?: number | null;
          fixed?: number | null;
          min_fee?: number | null;
        } | null;
      } | null;
      service_fee_enabled: boolean;
      visa?: {
        __typename: 'CardBrandFee';
        merchant_fee?: {
          __typename: 'CardMerchantFee';
          basis_points?: number | null;
          fixed?: number | null;
        } | null;
      } | null;
    } | null;
    fee_model?: {
      __typename: 'FeeModel';
      interchange_plus?: boolean | null;
      merchant_fee?: {
        __typename: 'FeeModelDetail';
        ach_basis?: number | null;
        ach_fixed?: number | null;
        card_basis?: number | null;
        card_fixed?: number | null;
      } | null;
      service_fee?: {
        __typename: 'FeeModelDetail';
        ach_basis?: number | null;
        ach_fixed?: number | null;
        card_basis?: number | null;
        card_fixed?: number | null;
      } | null;
      service_fee_min?: {
        __typename: 'FeeModelDetail';
        ach_basis?: number | null;
        ach_fixed?: number | null;
        card_basis?: number | null;
        card_fixed?: number | null;
      } | null;
    } | null;
    is_system?: boolean | null;
    merchant_name?: string | null;
    merchant_uid?: string | null;
    metadata?: string | null;
    parent_merchant_uid?: string | null;
    settings?: {
      __typename: 'MerchantSettings';
      contact_email?: string | null;
      contact_phone?: string | null;
      facebook?: string | null;
      instagram?: string | null;
      linkedin?: string | null;
      tiktok?: string | null;
      twitter?: string | null;
      website?: string | null;
    } | null;
    submitted_onboarding?: boolean | null;
  } | null;
};

export type MerchantOnboardingQueryVariables = {
  merchant_uid: string;
};

export type MerchantOnboardingQuery = {
  // Get onboarding data
  merchantOnboarding?: {
    __typename: 'MerchantOnboarding';
    banks?: Array<{
      __typename: 'BusinessBank';
      account_number?: string | null;
      account_type?: BankAccountType | null;
      account_uses?: Array<BankAccountUse | null> | null;
      bank_code?: string | null;
      bank_name?: string | null;
      institution_number?: string | null;
      name?: string | null;
      transit_number?: string | null;
    } | null> | null;
    business?: {
      __typename: 'Business';
      additional_underwriting_data?: {
        __typename: 'AdditionalUnderwritingData';
        annual_ach_volume?: string | null;
        average_ach_transfer_amount?: number | null;
        average_card_transfer_amount?: number | null;
        business_description?: string | null;
        card_volume_distribution?: {
          __typename: 'CardVolumeDistribution';
          card_present_percentage?: number | null;
          ecommerce_percentage?: number | null;
        } | null;
        credit_check_allowed?: boolean | null;
        credit_check_ip_address?: string | null;
        credit_check_timestamp?: string | null;
        credit_check_user_agent?: string | null;
        merchant_agreement_accepted?: boolean | null;
        merchant_agreement_ip_address?: string | null;
        merchant_agreement_timestamp?: string | null;
        merchant_agreement_user_agent?: string | null;
        statement_descriptor?: string | null;
        refund_policy?: RefundPolicy | null;
        volume_distribution_by_business_type?: {
          __typename: 'VolumeDistributionByBusinessType';
          business_to_business_volume_percentage?: number | null;
          business_to_consumer_volume_percentage?: number | null;
          other_volume_percentage?: number | null;
        } | null;
      } | null;
      underwriting_data?: {
        __typename: 'UnderwritingData';
        annual_card_volume?: string | null;
        business_address?: {
          __typename: 'Address';
          city?: string | null;
          country?: string | null;
          line1?: string | null;
          line2?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        has_accepted_credit_cards_previously?: boolean | null;
        business_name?: string | null;
        business_type?: BusinessType | null;
        delivery_0_days_percent?: number | null;
        delivery_1_to_7_days_percent?: number | null;
        delivery_8_to_14_days_percent?: number | null;
        delivery_15_to_30_days_percent?: number | null;
        delivery_over_30_days_percent?: number | null;
        doing_business_as?: string | null;
        incorporation_date?: string | null;
        incorporation_state?: UsStates | null;
        max_transaction_amount?: number | null;
        mcc?: string | null;
        phone?: string | null;
        prod_serv_sold?: string | null;
        ownership_type?: OwnershipType | null;
        tax_id?: string | null;
        url?: string | null;
      } | null;
    } | null;
    business_owners?: Array<{
      __typename: 'BusinessOwner';
      address?: {
        __typename: 'Address';
        city?: string | null;
        country?: string | null;
        line1?: string | null;
        line2?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      dob?: string | null;
      email?: string | null;
      first_name?: string | null;
      last_name?: string | null;
      middle_name?: string | null;
      nationality?: CountryCodes_Alpha3 | null;
      owner_position?: OwnerPosition | null;
      percentage_ownership?: number | null;
      phone?: string | null;
      primary_owner?: boolean | null;
      tax_id?: string | null;
      title?: string | null;
      uid: string;
    } | null> | null;
    country_code_of_operation?: CountryCodes_Alpha3 | null;
    is_locked?: boolean | null;
    merchant_uid: string;
    needs_docs?: boolean | null;
    ticket_id?: number | null;
    user_email?: string | null;
    user_full_name?: string | null;
  } | null;
};

export type MerchantsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type MerchantsQuery = {
  merchants?: {
    __typename: 'Merchants';
    items?: Array<{
      __typename: 'ListMerchant';
      ach_active?: boolean | null;
      card_active?: boolean | null;
      cash_active?: boolean | null;
      country_code?: string | null;
      is_system?: boolean | null;
      merchant_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      parent_merchant_uid?: string | null;
      submitted_onboarding?: boolean | null;
      updated_row_at?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type MissedRecurringPaymentDataQueryVariables = {
  recurring_id: string;
};

export type MissedRecurringPaymentDataQuery = {
  missedRecurringPaymentData?: {
    __typename: 'MissedRecurringPaymentData';
    fee?: number | null;
    number_of_payments_missed?: number | null;
    total_amount_owed?: number | null;
  } | null;
};

export type NativeAppsQueryVariables = {};

export type NativeAppsQuery = {
  nativeApps?: Array<{
    __typename: 'NativeApp';
    app_id?: string | null;
    apple_pay_expiration_date?: string | null;
    description?: string | null;
    device_check_key?: string | null;
    device_check_kid?: string | null;
    merchant_uid?: string | null;
    platform?: NativeAppPlatform | null;
  } | null> | null;
};

export type PaymentLinkCheckoutPageQueryVariables = {
  link_id: string;
};

export type PaymentLinkCheckoutPageQuery = {
  paymentLinkCheckoutPage?: {
    __typename: 'PaymentLinkForCheckout';
    accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
    account_code?: string | null;
    amount?: number | null;
    amount_is_variable?: boolean | null;
    api_key?: string | null;
    call_to_action?: CallToActionType | null;
    country_code?: string | null;
    currency?: string | null;
    custom_success_message?: string | null;
    fee_mode?: FeeMode | null;
    is_active?: boolean | null;
    max_amount?: number | null;
    merchant_name?: string | null;
    merchant_uid?: string | null;
    min_amount?: number | null;
    payment_description?: string | null;
    payment_name?: string | null;
    redirect_url?: string | null;
    require_phone?: boolean | null;
  } | null;
};

export type PaymentLinksQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type PaymentLinksQuery = {
  paymentLinks?: {
    __typename: 'PaymentLinks';
    items?: Array<{
      __typename: 'PaymentLink';
      accepted_payment_methods?: AcceptedPaymentMethodsEnum | null;
      account_code?: string | null;
      amount?: number | null;
      amount_is_variable?: boolean | null;
      call_to_action?: CallToActionType | null;
      created_date?: string | null;
      currency?: string | null;
      custom_success_message?: string | null;
      fee_mode?: FeeMode | null;
      is_active?: boolean | null;
      link_id?: string | null;
      link_name?: string | null;
      link_url?: string | null;
      max_amount?: number | null;
      merchant_uid?: string | null;
      min_amount?: number | null;
      payment_name?: string | null;
      payment_description?: string | null;
      redirect_url?: string | null;
      require_phone?: boolean | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type PaymentMethodTokensQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type PaymentMethodTokensQuery = {
  paymentMethodTokens?: {
    __typename: 'PaymentMethodTokens';
    items?: Array<{
      __typename: 'PaymentMethodToken';
      address_line1?: string | null;
      address_line2?: string | null;
      bank_account_type?: BankAccountType | null;
      bank_code?: string | null;
      barcode_id?: string | null;
      card_brand?: string | null;
      card_type?: CardType | null;
      city?: string | null;
      country?: string | null;
      exp_date?: string | null;
      full_name?: string | null;
      is_active?: boolean | null;
      issuing_country_code?: string | null;
      last_four?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payment_method_id?: string | null;
      payment_type?: PaymentType | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      postal_code?: string | null;
      region?: string | null;
      wallet_type?: WalletType | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type PayorsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type PayorsQuery = {
  // This queries payors
  payors?: {
    __typename: 'Payors';
    items?: Array<{
      __typename: 'Payor';
      address_line1?: string | null;
      address_line2?: string | null;
      city?: string | null;
      country?: string | null;
      email?: string | null;
      full_name?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      payor_id?: string | null;
      phone?: string | null;
      postal_code?: string | null;
      region?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type RecurringUpdatePageQueryVariables = {
  recurring_hash: string;
};

export type RecurringUpdatePageQuery = {
  recurringUpdatePage?: {
    __typename: 'RecurringUpdateDetails';
    amount?: number | null;
    api_key?: string | null;
    hash?: string | null;
    merchant_name?: string | null;
    payor_id?: string | null;
    payor_name?: string | null;
    recurring_description?: string | null;
    recurring_name?: string | null;
  } | null;
};

export type RecurringPaymentsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type RecurringPaymentsQuery = {
  recurringPayments?: {
    __typename: 'RecurringPayments';
    items?: Array<{
      __typename: 'RecurringPayment';
      account_code?: string | null;
      amount_per_payment?: number | null;
      created_date?: string | null;
      currency?: string | null;
      fee_mode?: FeeMode | null;
      fee_per_payment?: number | null;
      is_active?: boolean | null;
      is_processing?: boolean | null;
      recurring_id?: string | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      mute_all_emails?: boolean | null;
      next_payment_date?: string | null;
      payment_interval?: RecurringInterval | null;
      payment_method?: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      } | null;
      payor?: {
        __typename: 'Payor';
        address_line1?: string | null;
        address_line2?: string | null;
        city?: string | null;
        country?: string | null;
        email?: string | null;
        full_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payor_id?: string | null;
        phone?: string | null;
        postal_code?: string | null;
        region?: string | null;
      } | null;
      prev_payment_date?: string | null;
      recurring_description?: string | null;
      recurring_name?: string | null;
      reference?: string | null;
      remaining_payments?: number | null;
      status?: RecurringStatus | null;
      total_amount_per_payment?: number | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type ServiceFeeQueryVariables = {
  merchant_uid: string;
  amount: number;
  bank_id?: string | null;
  payment_type?: string | null;
  is_ach?: boolean | null;
  payment_method_id?: string | null;
};

export type ServiceFeeQuery = {
  serviceFee: {
    __typename: 'ServiceFeeCalculation';
    fee_limit_reached?: boolean | null;
    fee?: number | null;
    total?: number | null;
    adjusted_total?: number | null;
  };
};

export type ServiceFeeAmountQueryVariables = {
  merchant_uid: string;
  amount: number;
};

export type ServiceFeeAmountQuery = {
  // Deprecated, use serviceFee instead
  serviceFeeAmount?: {
    __typename: 'ServiceFeeAmount';
    ach?: {
      __typename: 'ServiceFeeCalculation';
      fee_limit_reached?: boolean | null;
      fee?: number | null;
      total?: number | null;
      adjusted_total?: number | null;
    } | null;
    card?: {
      __typename: 'ServiceFeeCalculation';
      fee_limit_reached?: boolean | null;
      fee?: number | null;
      total?: number | null;
      adjusted_total?: number | null;
    } | null;
  } | null;
};

export type SettlementsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type SettlementsQuery = {
  settlements?: {
    __typename: 'Settlements';
    items?: Array<{
      __typename: 'Settlement';
      currency?: string | null;
      gross_amount?: number | null;
      gross_amount_64bit?: string | null;
      merchant_uid?: string | null;
      net_amount?: number | null;
      net_amount_64bit?: string | null;
      settlement_batch?: number | null;
      settlement_date?: string | null;
      status?: string | null;
      transaction_debit_count?: number | null;
      transaction_dispute_count?: number | null;
      transaction_reversal_count?: number | null;
      transfer_date?: string | null;
      total_adjustments?: number | null;
      total_adjustments_64bit?: string | null;
      total_fees?: number | null;
      total_fees_64bit?: string | null;
      updated_row_at?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type SplitsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type SplitsQuery = {
  // Return split funding records
  splits?: {
    __typename: 'Splits';
    items?: Array<{
      __typename: 'Split';
      account_code?: string | null;
      amount: number;
      currency: string;
      created_at: string;
      id: string;
      merchant: {
        __typename: 'ListMerchant';
        ach_active?: boolean | null;
        card_active?: boolean | null;
        cash_active?: boolean | null;
        country_code?: string | null;
        is_system?: boolean | null;
        merchant_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        parent_merchant_uid?: string | null;
        submitted_onboarding?: boolean | null;
        updated_row_at?: string | null;
      };
      metadata: string;
      payment_method: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      };
      reference?: string | null;
      settlement_batch?: number | null;
      transaction_id: string;
      updated_row_at: string;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type SupportConversationQueryVariables = {
  merchant_uid: string;
  ticket_id: string;
};

export type SupportConversationQuery = {
  // Get the fresh desk conversation data associated with the passed in ticket ID
  supportConversation?: {
    __typename: 'SupportConversation';
    support_notes?: Array<{
      __typename: 'SupportNote';
      body?: string | null;
      support_docs?: Array<{
        __typename: 'Document';
        attachment_id: string;
        doc_type: SupportDocumentType;
        name: string;
      } | null> | null;
    } | null> | null;
    ticket_id: string;
  } | null;
};

export type TransactionsQueryVariables = {
  query?: SqlQuery | null;
  limit?: number | null;
  offset?: string | null;
  direction?: MoveDirection | null;
  offset_id?: string | null;
};

export type TransactionsQuery = {
  transactions?: {
    __typename: 'Transactions';
    items?: Array<{
      __typename: 'Transaction';
      account_code?: string | null;
      ach_return_details?: {
        __typename: 'AchReturnDetails';
        return_code?: string | null;
        return_details?: string | null;
        transfer_type?: AchReturnTransferType | null;
      } | null;
      additional_purchase_data?: {
        __typename: 'AdditionalPurchaseData';
        level3_data_line_item?: Array<{
          __typename: 'Level3DataLineItem';
          item_code?: string | null;
          item_description?: string | null;
          item_qty_exp?: number | null;
          prod_code?: string | null;
          qty?: number | null;
          tax_amount?: number | null;
          tax_ind?: TaxIndicatorType | null;
          tax_rate?: number | null;
          tax_rt_exp?: number | null;
          tax_type_id?: TaxType | null;
          unit_cost?: number | null;
          unit_of_msure?: string | null;
        } | null> | null;
        level3_data_summary?: {
          __typename: 'Level3DataSummary';
          dest_postal_code?: string | null;
          discnt_amt?: number | null;
          duty_amt?: number | null;
          frght_amt?: number | null;
          order_num?: string | null;
          prod_desc?: Array<string | null> | null;
          purch_idfr?: string | null;
          tax_amt?: number | null;
          tax_ind?: TaxIndicatorType | null;
        } | null;
      } | null;
      authorization_id?: string | null;
      avs_status?: string | null;
      currency?: string | null;
      device_id?: string | null;
      dispute_status?: DisputeStatus | null;
      failure_reasons?: Array<string | null> | null;
      fee_mode?: FeeMode | null;
      fees?: number | null;
      flag_for_review?: TransactionReviewStatus | null;
      gross_amount?: number | null;
      invoice?: {
        __typename: 'Invoice';
        account_code?: string | null;
        created_date?: string | null;
        currency?: string | null;
        due_by?: string | null;
        fee_mode?: FeeMode | null;
        invoice_amount?: number | null;
        invoice_date?: string | null;
        invoice_description?: string | null;
        invoice_id?: string | null;
        invoice_name?: string | null;
        merchant_invoice_number?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        offline_transactions?: Array<{
          __typename: 'OfflineTransaction';
          amount?: number | null;
          instance_id?: string | null;
          invoice_id?: string | null;
          note?: string | null;
          transaction_date?: string | null;
          type?: OfflineTransactionType | null;
        } | null> | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        reference?: string | null;
        settings?: {
          __typename: 'InvoiceSettings';
          accepted_payment_methods?: {
            __typename: 'AcceptedPaymentMethods';
            ach?: boolean | null;
            card?: boolean | null;
            cash?: boolean | null;
          } | null;
          is_secure?: boolean | null;
          require_payor_address?: boolean | null;
          security_pin?: string | null;
        } | null;
        status?: InvoiceStatus | null;
        total_paid_amount?: number | null;
      } | null;
      is_settled?: boolean | null;
      merchant?: {
        __typename: 'ListMerchant';
        ach_active?: boolean | null;
        card_active?: boolean | null;
        cash_active?: boolean | null;
        country_code?: string | null;
        is_system?: boolean | null;
        merchant_name?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        parent_merchant_uid?: string | null;
        submitted_onboarding?: boolean | null;
        updated_row_at?: string | null;
      } | null;
      merchant_uid?: string | null;
      metadata?: string | null;
      net_amount?: number | null;
      parent_id?: string | null;
      payment_method?: {
        __typename: 'PaymentMethodToken';
        address_line1?: string | null;
        address_line2?: string | null;
        bank_account_type?: BankAccountType | null;
        bank_code?: string | null;
        barcode_id?: string | null;
        card_brand?: string | null;
        card_type?: CardType | null;
        city?: string | null;
        country?: string | null;
        exp_date?: string | null;
        full_name?: string | null;
        is_active?: boolean | null;
        issuing_country_code?: string | null;
        last_four?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        payment_method_id?: string | null;
        payment_type?: PaymentType | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        postal_code?: string | null;
        region?: string | null;
        wallet_type?: WalletType | null;
      } | null;
      processor?: string | null;
      recurring?: {
        __typename: 'RecurringPayment';
        account_code?: string | null;
        amount_per_payment?: number | null;
        created_date?: string | null;
        currency?: string | null;
        fee_mode?: FeeMode | null;
        fee_per_payment?: number | null;
        is_active?: boolean | null;
        is_processing?: boolean | null;
        recurring_id?: string | null;
        merchant_uid?: string | null;
        metadata?: string | null;
        mute_all_emails?: boolean | null;
        next_payment_date?: string | null;
        payment_interval?: RecurringInterval | null;
        payment_method?: {
          __typename: 'PaymentMethodToken';
          address_line1?: string | null;
          address_line2?: string | null;
          bank_account_type?: BankAccountType | null;
          bank_code?: string | null;
          barcode_id?: string | null;
          card_brand?: string | null;
          card_type?: CardType | null;
          city?: string | null;
          country?: string | null;
          exp_date?: string | null;
          full_name?: string | null;
          is_active?: boolean | null;
          issuing_country_code?: string | null;
          last_four?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payment_method_id?: string | null;
          payment_type?: PaymentType | null;
          payor?: {
            __typename: 'Payor';
            address_line1?: string | null;
            address_line2?: string | null;
            city?: string | null;
            country?: string | null;
            email?: string | null;
            full_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payor_id?: string | null;
            phone?: string | null;
            postal_code?: string | null;
            region?: string | null;
          } | null;
          postal_code?: string | null;
          region?: string | null;
          wallet_type?: WalletType | null;
        } | null;
        payor?: {
          __typename: 'Payor';
          address_line1?: string | null;
          address_line2?: string | null;
          city?: string | null;
          country?: string | null;
          email?: string | null;
          full_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payor_id?: string | null;
          phone?: string | null;
          postal_code?: string | null;
          region?: string | null;
        } | null;
        prev_payment_date?: string | null;
        recurring_description?: string | null;
        recurring_name?: string | null;
        reference?: string | null;
        remaining_payments?: number | null;
        status?: RecurringStatus | null;
        total_amount_per_payment?: number | null;
      } | null;
      reference?: string | null;
      refund_reason?: {
        __typename: 'RefundReason';
        reason_code?: RefundReasonCode | null;
        reason_details?: string | null;
      } | null;
      refund_voidable?: boolean | null;
      refunded_amount?: number | null;
      sale_id?: string | null;
      settlement_batch?: number | null;
      splits?: Array<{
        __typename: 'Split';
        account_code?: string | null;
        amount: number;
        currency: string;
        created_at: string;
        id: string;
        merchant: {
          __typename: 'ListMerchant';
          ach_active?: boolean | null;
          card_active?: boolean | null;
          cash_active?: boolean | null;
          country_code?: string | null;
          is_system?: boolean | null;
          merchant_name?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          parent_merchant_uid?: string | null;
          submitted_onboarding?: boolean | null;
          updated_row_at?: string | null;
        };
        metadata: string;
        payment_method: {
          __typename: 'PaymentMethodToken';
          address_line1?: string | null;
          address_line2?: string | null;
          bank_account_type?: BankAccountType | null;
          bank_code?: string | null;
          barcode_id?: string | null;
          card_brand?: string | null;
          card_type?: CardType | null;
          city?: string | null;
          country?: string | null;
          exp_date?: string | null;
          full_name?: string | null;
          is_active?: boolean | null;
          issuing_country_code?: string | null;
          last_four?: string | null;
          merchant_uid?: string | null;
          metadata?: string | null;
          payment_method_id?: string | null;
          payment_type?: PaymentType | null;
          payor?: {
            __typename: 'Payor';
            address_line1?: string | null;
            address_line2?: string | null;
            city?: string | null;
            country?: string | null;
            email?: string | null;
            full_name?: string | null;
            merchant_uid?: string | null;
            metadata?: string | null;
            payor_id?: string | null;
            phone?: string | null;
            postal_code?: string | null;
            region?: string | null;
          } | null;
          postal_code?: string | null;
          region?: string | null;
          wallet_type?: WalletType | null;
        };
        reference?: string | null;
        settlement_batch?: number | null;
        transaction_id: string;
        updated_row_at: string;
      } | null> | null;
      status?: TransactionStatus | null;
      timezone?: string | null;
      transaction_date?: string | null;
      transaction_id?: string | null;
      transaction_type?: TransactionType | null;
      updated_row_at?: string | null;
    } | null> | null;
    total_row_count?: number | null;
  } | null;
};

export type UsersQueryVariables = {
  user_pool: UserPool;
  merchant_uid?: string | null;
};

export type UsersQuery = {
  users?: Array<{
    __typename: 'User';
    email?: string | null;
    full_name?: string | null;
    phone?: string | null;
    user_status?: string | null;
    username?: string | null;
  } | null> | null;
};

export type UsersV2QueryVariables = {
  user_pool: UserPool;
  merchant_uid?: string | null;
};

export type UsersV2Query = {
  usersV2?: Array<{
    __typename: 'UserV2';
    user_id?: string | null;
    email?: string | null;
    full_name?: string | null;
    user_pool_id?: UserPool | null;
    merchant_uid?: string | null;
    permissions?: string | null;
  } | null> | null;
};

export type WalletRegistrationQueryVariables = {
  partner_internal_merchant_identifier?: string | null;
  wallet_type?: WalletType | null;
};

export type WalletRegistrationQuery = {
  walletRegistration: Array<{
    __typename: 'WalletApple';
    domain_names: Array<string | null>;
    registered_domain_names: Array<string | null>;
    partner_internal_merchant_identifier: string;
    partner_merchant_name: string;
    wallet_type: WalletType;
  } | null>;
};

export type WebhooksQueryVariables = {
  endpoint?: string | null;
};

export type WebhooksQuery = {
  webhooks: Array<{
    __typename: 'Webhook';
    endpoint: string;
    // Url the event will be sent to
    is_active: boolean;
    // Whether the webhook is active (receiving notifications)
    name: string;
  } | null>;
};

export type WebhookEventsQueryVariables = {
  id?: string | null;
  endpoint?: string | null;
  result?: WebhookNotificationResult | null;
  last_evaluated_key?: string | null;
  limit?: number | null;
};

export type WebhookEventsQuery = {
  webhookEvents: {
    __typename: 'WebhookEvents';
    events: Array<{
      __typename: 'WebhookEvent';
      endpoint: string;
      // URL the event was sent to
      error?: string | null;
      // Error message if the event failed
      event: string;
      // Type of event that was sent
      finished_at: string;
      // Time the last response was received (or last attempt was made)
      id: string;
      // Unique ID for the event
      request: string;
      // Request that was sent to the endpoint
      response?: string | null;
      // Data from the last response, null if no response was received
      result: WebhookNotificationResult;
      // Final result of the event
      started_at: string;
      // Time the first request was made
      status_code?: number | null;
    } | null>;
    // List of events that have been sent to the endpoint
    last_evaluated_key?: string | null;
  };
};

export type SubscribeCardPresentCompletionSubscriptionVariables = {
  transaction_id: string;
};

export type SubscribeCardPresentCompletionSubscription = {
  subscribeCardPresentCompletion?: {
    __typename: 'CardPresentPayment';
    amount?: number | null;
    card_brand?: string | null;
    created_at?: string | null;
    currency?: string | null;
    failure_reason?: Array<string | null> | null;
    last_four?: string | null;
    service_fee?: number | null;
    status?: TransactionStatus | null;
    transaction_id?: string | null;
  } | null;
};

export type SubscribeNotificationSubscriptionVariables = {
  merchant_uid: string;
  context_id?: NotificationContextId | null;
  primary_value?: string | null;
  secondary_value?: string | null;
  error?: string | null;
};

export type SubscribeNotificationSubscription = {
  subscribeNotification?: {
    __typename: 'Notification';
    context_id: NotificationContextId;
    error?: string | null;
    merchant_uid: string;
    primary_value?: string | null;
    secondary_value?: string | null;
  } | null;
};
